.arrows {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;

  &__item {
    position: relative;
    display: inline-block;

    &_left {
      &:hover {
        svg {
          transform: translateX(-6px);
        }
      }

      .arrows {
        &__icon {
          &::before {
            left: 50%;
            right: 0;
          }
        }
      }
    }

    &_right {
      &:hover {
        svg {
          transform: translateX(6px);
        }
      }

      .arrows {
        &__icon {
          &::before {
            right: 50%;
            left: 0;
          }
        }
      }
    }

    svg {
      transition: transform $default-transition;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      height: 2px;
      background-color: $green-color;
    }
  }

  &__border {
    width: 3px;
    height: 31px;
    background-color: $magnolia-color;
    cursor: pointer;
  }
}

@include respond-up('large') {
  .arrows {
    &__item {
      padding: 20px 26px;
    }
  }
}

@include respond-down('medium') {
  .arrows {
    &__item {
      padding-top: 12px;
      padding-bottom: 12px;

      &_left {
        padding-right: 26px;
        padding-left: 18px;
      }

      &_right {
        padding-right: 18px;
        padding-left: 26px;
      }
    }
  }
}

@include respond-down('small') {
  .arrows {
    justify-content: center;

    &__item {
      padding: 10px 25px;
    }
  }
}