.about-video {
  position: relative;

  &__main {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 20;
  }

  &__preview {
    display: block;
    transition: transform .8s ease-in-out;
  }

  &__info {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(51,53,49,0.63);
  }

  &__play,
  svg {
    transition: transform .8s ease-in-out;
  }

  svg {
    &:hover {
      transform: scale(1.08);
    }
  }

  &__name {
    color: #fff;
    letter-spacing: 0;
    text-align: center;
    line-height: 1;
    border-bottom: 1px solid $t;
    transition: border-bottom-color .8s ease-in-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__wrap {
    position: absolute;
    z-index: 10;
  }

  &__frame {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__chip {
    position: absolute;
  }
}

@include respond-up('x-large') {
  .about-video {
    &__wrap {
      top: 82px;
      right: 118px;
      bottom: -82px;
      left: -94px;
    }

    &__chip {
      left: 150px;
      bottom: -87px;
    }
  }
}

@include respond-up('large') {
  .about-video {
    width: 38vw;


    &__main {
      &:hover {
        .about-video {
          &__preview,
          &__play {
            transform: scale(1.04);
          }
        }
      }
    }

    &__play {
      &:hover + div {
        border-bottom: 1px solid #fff;
      }
    }

    &__name {
      &:hover {
        border-bottom: 1px solid #fff;
      }
    }

    &__wrap {
      outline: 25px solid $magnolia-color;
      outline-offset: -25px;
    }
  }
}

@include respond('large') {
  .about-video {
    &__wrap {
      top: 75px;
      right: 95px;
      bottom: -70px;
      left: -75px;
    }

    &__chip {
      left: 110px;
      bottom: -79px;
    }
  }
}

@include respond-up('medium') {
  .about-video {
    &__play {
      padding-bottom: 20px;
    }

    &__name {
      padding-top: 20px;
      font-weight: 800;
      font-size: 28px;
    }

    &__chip {
      width: 184px;
    }
  }
}

@include respond('medium') {
  .about-video {
    &__main {
      margin-left: 10vw;
    }

    &__wrap {
      top: 10vw;
      right: 12vw;
      bottom: -10vw;
      left: 0;
      outline: 20px solid $magnolia-color;
      outline-offset: -20px;
    }

    &__chip {
      left: 100px;
      bottom: -85px;
    }
  }
}

@include respond-down('medium') {
  .about-video {
    &__main {
      &:hover {
        .about-video {
          &__name {
            border-bottom: 1px solid #fff;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .about-video {
    &__play {
      padding-bottom: 8px;

      svg {
        height: 69px;
        width: 69px;
      }
    }

    &__name {
      padding-top: 8px;
      font-weight: 700;
      font-size: 18px;
    }

    &__wrap {
      top: 40px;
      right: -65px;
      bottom: -40px;
      left: 32px;
      outline: 14px solid $magnolia-color;
      outline-offset: -14px;
    }

    &__chip {
      width: 103px;
      height: 75px;
      left: 82px;
      bottom: -47px;
    }
  }
}