.contacts {
  &__top-container {

  }

  &__info-list {

  }

  &__info-item {

  }

  &__info-link {
    display: flex;
    flex-direction: column;
  }

  &__info-label {
    display: block;
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
    line-height: 1.4;
  }

  &__info-text {
    display: block;
    font-weight: bold;
    color: #333531;
    letter-spacing: 0;
    &_address {
      font-weight: 500;
    }
  }

  &__map-container {

  }

  &__map {

  }

  &__wrapper {
    pointer-events: none;
  }

  &__container {

  }

  &__info-container {
    pointer-events: auto;
  }

  &__info {

  }

  &__info-social-list {
    display: flex;
  }

  &__info-social-link {
    width: 41px;
    height: 41px;
    border-radius: 50%;
    border: 2px solid #ECECE3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all.3s ease-in-out;
    margin-right: 9px;
    &:hover {
      background-color: $green-color;
      border: 2px solid $green-color;
      .contacts__info-social-icon {
        filter: grayscale(300%) brightness(300%);
      }
    }
  }

  &__info-social-icon {
    transition: all.3s ease-in-out;
  }

  &__info-social-item:nth-child(1) {
    .contacts__info-social-icon {
      position: relative;
      left: -1px;
      top: -0.01em;
    }
  }

  &__info-social-item:nth-child(2) {
    .contacts__info-social-icon {
      position: relative;
      top: -0.01em;
    }
  }

  &__requisite {
    color: rgba(51,53,49,0.80);
    letter-spacing: 0;
  }
}

@include respond-up('large') {
  .contacts {
    &__top-container {
      display: none;
    }

    &__info-list {

    }

    &__info-item {
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__info-link {

    }

    &__info-label {
      font-size: 15px;
      padding-bottom: 6px;
    }

    &__info-text {
      font-size: 19px;
      &_address {
        font-size: 16px;
      }
    }

    &__map-container {
      position: relative;
    }

    &__map {
      height: 700px;
    }

    &__wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .row, .column {
        height: 100%;
      }
    }

    &__container {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__info-container {
      max-width: 372px;
      background-color: #F6F6F1;
      padding: 25px;
    }

    &__info {
      background-color: white;
      padding: 49px 42px;
    }

    &__requisites {
      display: flex;
      padding: 36px 0 42px;
    }

    &__requisite {
      flex: 0 0 50%;
      padding-right: 40px;
      font-size: 15px;
      line-height: 22/15*1em;
    }
  }
}

@include respond('medium') {
  .contacts {
    &__top-container {
      display: block;
      margin-bottom: 58px;
    }

    &__info-list {
      display: flex;
      flex-wrap: wrap;
    }

    &__info-item {
      flex: 0 0 33.33333%;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__info-link {

    }

    &__info-label {
      font-size: 15px;
      padding-bottom: 6px;
    }

    &__info-text {
      font-size: 17px;
      &_address {
        font-size: 15px;
      }
    }

    &__map-container {
      position: relative;
    }

    &__map {
      height: 643px;
    }

    &__wrapper {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .contacts {
    &__requisites {
      padding: 20px 0 30px;
    }

    &__requisite {
      font-size: 14px;
      line-height: 20/14*1em;
    }
  }
}

@include respond-down('small') {
  .contacts {
    &__top-container {
      display: block;
      margin-bottom: 58px;
    }

    &__info-list {
      display: flex;
      flex-wrap: wrap;
    }

    &__info-item {
      flex: 0 0 50%;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__info-label {
      font-size: 14px;
      padding-bottom: 6px;
    }

    &__info-text {
      font-size: 15px;
      &_address {
        font-size: 14px;
      }
    }

    &__map-container {
      position: relative;
    }

    &__map {
      height: 643px;
    }

    &__wrapper {
      display: none;
    }
  }
}