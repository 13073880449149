.about-block {
  position: relative;

  &__leaf {
    position: absolute;
    background: url("../images/index/leaf-about.png") no-repeat left center / contain;
    left: 0;
    height: 10vw;
    width: 9vw;
    max-height: 195px;
    max-width: 175px;
  }

  &__item {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__item-number {
    font-weight: 800;
    color: $primary-color;
    letter-spacing: 0;
    line-height: 1;
  }

  &__item-name {
    font-weight: 700;
    letter-spacing: 0;
  }

  &__item-text {
    color: $gray-color;
    letter-spacing: 0;
  }
}

@include respond-up('x-large') {
  .about-block {
    &__main {
      padding-top: 186px;
    }

    &__title {
      padding-bottom: 90px;
    }

    &__item {
      max-width: 390px;


      &:first-child {
        padding-bottom: 100px;
      }
    }

    &__item-name {
      padding-top: 18px;
      font-size: 26px;
      line-height: 1.23;
    }

    &__item-text {
      font-size: 17px;
      line-height: 1.58;
    }

    &__advantages {
      padding-top: 194px;
    }
  }
}

@include respond-up('large') {
  .about-block {
    &__main {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__title {
      flex: 0 0 100%;
    }

    &__item-number {
      font-size: 70px;
    }

    &__leaf {
      top: 150px;
    }
  }
}

@include respond('large') {
  .about-block {
    &__main {
      padding-top: 156px;
    }

    &__title {
      padding-bottom: 80px;
    }

    &__item {
      max-width: 330px;

      &:first-child {
        padding-bottom: 60px;
      }
    }

    &__item-name {
      padding-top: 8px;
      font-size: 23px;
      line-height: 1.39;
    }

    &__advantages {
      padding-top: 162px;
    }
  }
}

@include respond-down('large') {
  .about-block {
    &__item-text {
      font-size: 16px;
      line-height: 1.375;
    }
  }
}

@include respond-up('medium') {
  .about-block {
    &__item-text {
      padding-top: 20px;
    }
  }
}

@include respond('medium') {
  .about-block {
    &__main {
      padding-top: 80px;
    }

    &__title {
      padding-bottom: 60px;
    }

    &__items {
      display: flex;
      margin: 0 -12px;
    }

    &__item {
      padding: 0 12px;
    }

    &__item-name {
      padding-top: 10px;
      font-size: 23px;
      line-height: 1.39;
    }

    &__video {
      padding-top: 68px;
    }

    &__advantages {
      padding-top: 200px;
    }

    &__leaf {
      top: 40px;
    }
  }
}

@include respond-down('medium') {
  .about-block {
    &__item-number {
      font-size: 50px;
    }
  }
}

@include respond-down('small') {
  .about-block {
    overflow-x: hidden;

    &__main {
      padding-top: 40px;
    }

    &__title {
      padding-bottom: 40px;
    }

    &__item {
      &:first-child {
        padding-bottom: 40px;
      }
    }

    &__item-name {
      padding-top: 6px;
      font-size: 20px;
      line-height: 1;
    }

    &__item-text {
      padding-top: 16px;
    }

    &__video {
      padding-top: 50px;
    }

    &__advantages {
      padding-top: 115px;
    }

    &__leaf {
      top: 18px;
      height: 16vw;
      width: 14vw;
    }
  }
}