.product {
  &__container {

  }

  &__offer {

  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__table-description-container {
    flex: 0 0 66%;
  }

  &__table-container {

  }

  &__description-container {

  }

  &__description-title {
    font-weight: 800;
    color: $black-color;
    letter-spacing: 0;
  }

  &__description {

  }

  &__recommended-container {
    flex: 0 0 26%;
  }

  &__recommended-title {
    font-weight: 700;
    color: $black-color;
    letter-spacing: 0;
  }

  &__recommended-list {
    display: flex;
  }

  &__recommended-item {

  }

  &__recommended-link {
    background-color: $magnolia-color;
  }

  &__recommended-name {
    display: block;
    font-weight: 700;
    color: #CEAF72;
    letter-spacing: 0;
    line-height: 1;
  }

  &__recommended-image {
    display: block;
    background: {
      position: right 50%;
      size: contain;
      repeat: no-repeat;
    };
  }
}

@include respond-up('large') {
  .product {
    &__container {

    }

    &__offer {
      margin-bottom: 85px;
    }

    &__wrapper {
      margin-bottom: 128px;
    }

    &__table-description-container {

    }

    &__table-container {
      margin-bottom: 100px;
    }

    &__description-container {

    }

    &__description-title {
      font-size: 45px;
      margin-bottom: 25px;
    }

    &__description {

    }

    &__recommended-container {

    }

    &__recommended-title {
      font-size: 25px;
      margin-bottom: 24px;
    }

    &__recommended-list {
      flex-direction: column;
    }

    &__recommended-item {
      padding-bottom: 14px;
    }

    &__recommended-link {
      min-height: 85px;
      display: flex;
      justify-content: space-between;
    }

    &__recommended-name {
      font-size: 16px;
      max-width: 194px;
      padding: 18px 21px 19px;
    }

    &__recommended-image {
      height: 85px;
      width: 96px;
    }
  }
}

@include respond('medium') {
  .product {
    &__container {
      padding-bottom: 137px;
    }

    &__offer {

    }

    &__wrapper {
      flex-direction: column;
    }

    &__table-description-container {
      margin-bottom: 79px;
    }

    &__table-container {
      margin-top: 20px;
      margin-bottom: 100px;
    }

    &__description-container {

    }

    &__description-title {
      font-size: 38px;
      margin-bottom: 18px;
    }

    &__description {
      font-size: 17px;
      line-height: 1.7;
    }

    &__recommended-container {

    }

    &__recommended-title {
      font-size: 25px;
      margin-bottom: 24px;
    }

    &__recommended-list {
      margin: 0 -8px -16px;
      flex-wrap: wrap;
    }

    &__recommended-item {
      flex: 0 0 50%;
      padding: 0 8px 16px;
    }

    &__recommended-link {
      min-height: 85px;
      display: flex;
      justify-content: space-between;
    }

    &__recommended-name {
      font-size: 16px;
      max-width: 161px;
      padding: 18px 21px 19px;
    }

    &__recommended-image {
      height: 85px;
      width: 96px;
    }
  }
}

@include respond-down('small') {
  .product {
    &__container {
      padding-bottom: 60px;
    }

    &__offer {

    }

    &__wrapper {
      flex-direction: column;
    }

    &__table-description-container {
      margin-bottom: 50px;
    }

    &__table-container {
      margin-bottom: 50px;
    }

    &__description-container {

    }

    &__description-title {
      font-size: 25px;
      margin-bottom: 9px;
    }

    &__description {
      line-height: 1.4;
      font-size: 16px;

      a {
        color: $primary-color;
      }
    }

    &__recommended-container {

    }

    &__recommended-title {
      font-size: 25px;
      margin-bottom: 24px;
    }

    &__recommended-list {
      flex-wrap: wrap;
    }

    &__recommended-item {
      flex: 1 1 100%;
      padding-bottom: 14px;
    }

    &__recommended-link {
      min-height: 85px;
      display: flex;
      justify-content: space-between;
    }

    &__recommended-name {
      font-size: 16px;
      max-width: 161px;
      padding: 18px 21px 19px;
    }

    &__recommended-image {
      height: 85px;
      width: 96px;
    }
  }
}