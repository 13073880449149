.error-page {
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    font-weight: 600;
    color: #000;
    letter-spacing: 0;
  }
}

@include respond-up('medium') {
  .error-page {
    &__main {
      padding: 100px 0 80px;
    }

    &__text {
      padding-top: 80px;
      font-size: 40px;
    }

    &__buttons {
      padding-top: 60px;
    }

    &__button {
      min-width: 220px;
      margin: 0 30px;
    }
  }
}

@include respond-down('small') {
  .error-page {
    &__main {
      padding: 40px 0 40px;
    }

    &__404 {
      svg {
        width: 100%;
      }
    }

    &__text {
      padding-top: 6px;
      font-size: 26px;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
    }

    &__button {
      margin-top: 20px;
      min-width: 202px;
    }
  }
}
