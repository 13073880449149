.calculator {
  position: relative;
  &__title {
  }
  &__project-link {
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }
  }
  &__project-name {
    color: #FFFFFF;
  }
  &__price-left {
    display: flex;
  }
  .kit-info__title {
    display: none;
  }
  &__price {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1001;
    background-color: #FFFFFF;
  }
  &__project-list {
    display: flex;
    flex-wrap: wrap;
  }
}

@include respond-up('large') {
  .calculator {
    min-width: 792px;

    .kit-item__link {
      font-size: 24px;
    }
    .material-group {
      width: 240px;
    }
    .foundation-item__link {
      width: 240px;
    }
    .roof-item {
      width: 240px;
    }
    &__details {
      margin-bottom: 50px;
    }
    &__project-list {

    }
    &__project-link {
      display: block;
      position: relative;
      width: 230px;
      height: 137px;

      background: {
        repeat: no-repeat;
        size: cover;
        position: center;
      }
    }
  }
}

@include respond-up('large') {
  .calculator {
    &__title {
      font-size: 35px;
      font-weight: 800;
      margin-bottom: 72px;
    }
    &__project-item {
      margin:0 10px;
      margin-bottom: 20px;
      border: 9px solid transparent;
      &.active {
        border: 9px solid $primary-color;
      }
    }
    &__project-link {
      display: block;
      position: relative;
      width: 220px;
      height: 137px;

      background: {
        repeat: no-repeat;
        size: cover;
        position: center;
      }
    }
    &__project-list {
      margin-left: -10px;
      margin-bottom: 50px;
    }
    &__project-info {
      position: absolute;
      display: flex;
      bottom: 0;
      width: 100%;
      padding: 60px 10px 0px 10px;
      background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75));
      justify-content: space-between;
    }
    &__project-name {
      margin-right: 20px;
    }
    &__project-sizes {
      display: flex;
      align-items: center;
    }
    &__price {
      height: 80px;
    }
    &__price-label {
      font-size: 35px;
      font-weight: 500;
      margin-right: 16px;
    }
    &__price-value {
      font-size: 35px;
      color: $green-color;
      font-weight: 800;
    }
    &__price-button {
      padding: 22px;
      border: 2px solid $primary-color;
      color: $primary-color;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }
  }
}

@include respond-down('medium') {
  .calculator {
    &__title {
      font-size: 28px;
      font-weight: 800;
      margin-bottom: 30px;
    }
    &__price {
      height: 60px;
    }
    &__price-label {
      display: none;
      font-size: 16px;
    }
    &__price-value {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 800;
    }
    &__details {
      margin-bottom: 40px;
    }
    &__price-button {
      padding: 15px;
      border: 2px solid $primary-color;
      color: $primary-color;
      font-size: 10px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }
    &__project-list {
      margin-left: -5px;
      margin-bottom: 30px;
    }
    &__project-item {
      margin: 0 5px;
      border: 5px solid transparent;
      margin-bottom: 10px;
      &.active {
        border: 5px solid $primary-color;
      }
    }
    &__project-link {
      display: block;
      position: relative;
      width: 220px;
      height: 117px;


      background: {
        repeat: no-repeat;
        size: cover;
        position: center;
      }
    }
  }
}

@include respond-down('small') {
  .calculator {

  }
}