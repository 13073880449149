.content-header {

}

@include respond-up('large') {
  .content-header {
    padding: 100px 0 62px;
  }
}

@include respond('medium') {
  .content-header {
    padding: 44px 0 52px;
  }
}

@include respond-down('small') {
  .content-header {
    padding: 10px 0 28px;

    &__title {
      padding-top: 30px;
    }
  }
}