.about-prod {
  &__description {
    color: $gray-color;
    letter-spacing: 0;
  }

  &__button {
    margin-bottom: 10px;
    padding: 17px 10px;
  }
}

@include respond-up('x-large') {
  .about-prod {
    &__main {
      padding-top: 140px;
    }

    &__info {
      &_prod {
        padding-left: 300px;
      }
    }

    &__name {
      &::before {
        margin-bottom: 40px;
      }
    }

    &__description {
      padding-top: 27px;
    }
  }
}

@include respond-up('large') {
  .about-prod {
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:first-child {
        padding-bottom: 16vw;
      }
    }

    &__slider {
      flex: 0 0 48.5%;
    }

    &__image {
      flex: 0 0 768px;
      order: 2;
    }

    &__info {
      &_design {
        order: 1;
        flex: 0 0 700px;
        padding-right: 115px;
      }
    }

    &__button {
      margin-top: 60px;
    }
  }
}

@include respond('large') {
  .about-prod {
    &__main {
      padding-top: 130px;
    }

    &__slider {
      align-self: flex-start;
    }

    &__info {
      &_prod {
        padding-top: 30px;
        flex: 0 0 380px;
      }
    }

    &__name {
      &::before {
        margin-bottom: 45px;
      }
    }

    &__description {
      padding-top: 25px;
    }
  }
}

@include respond-up('medium') {
  .about-prod {
    &__name {
      &::before {
        content: '';
        display: block;
        border-bottom: 4px solid $green-color;
        width: 64px;
      }
    }

    &__description {
      font-size: 17px;
      line-height: 1.7;
    }

    &__button {
      min-width: 224px;
    }
  }
}

@include respond('medium') {
  .about-prod {
    &__main {
      padding-top: 80px;
    }

    &__item {
      &:first-child {
        padding-bottom: 64px;
      }
    }

    &__name {
      &::before {
        margin-bottom: 35px;
      }
    }

    &__description {
      padding-top: 18px;
    }

    &__button {
      margin-top: 52px;
    }
  }
}

@include respond-down('medium') {
  .about-prod {
    &__slider {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .about-prod {
    &__main {
      padding-top: 60px;
    }

    &__item {
      &:first-child {
        padding-bottom: 40px;
      }
    }

    &__description {
      padding-top: 9px;
    }

    &__info {
      &_design {
        margin-top: -30px;
      }
    }

    &__image {
      margin: 0 -20px;
    }

    &__img {
      display: block;
      object-fit: cover;
      height: 67vw;
    }

    &__button {
      margin-top: 40px;
      min-width: 186px;
    }
  }
}