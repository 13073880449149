.index-works {
  &__title {

  }

  &__main {
    position: relative;

    .tns-nav {
      display: none;
    }
  }

  &__items {
    .tns-item,
    .tns-slide-active,
    .tns-slide-active~.tns-slide-active~.tns-slide-active {
      transform: scale(.9);

      .index-works {
        &__text {
          opacity: 0;
          transform: translateY(100px);
        }
      }
    }

    .tns-slide-active~.tns-slide-active {
      transform: scale(1);

      .index-works {
        &__text {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__item {
    transition: transform .8s ease-in-out;
  }

  &__link {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__text {
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.80) 100%);
    font-weight: bold;
    color: #fff;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    transition: opacity .8s ease-in-out, transform .8s ease-in-out;
  }

  &__name {

  }

  &__arrow {
    position: absolute;
    padding: 20px 23px;
    background-color: #fff;
    top: calc(50% - 31px);

    &_left {
      left: calc((100vw - 37.2vw) / 2 - 60px);

      &:hover {
        svg {
          transform: translateX(-6px);
        }
      }

      .index-works {
        &__icon {
          &::before {
            left: 50%;
            right: 0;
          }
        }
      }
    }

    &_right {
      right: calc((100vw - 37.2vw) / 2 - 60px);

      &:hover {
        svg {
          transform: translateX(6px);
        }
      }

      .index-works {
        &__icon {
          &::before {
            right: 50%;
            left: 0;
          }
        }
      }
    }

    svg {
      transition: transform $default-transition;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      height: 2px;
      background-color: $green-color;
    }
  }
}

@include respond-up('x-large') {
  .index-works {
    &__item {
      height: 24.4vw;
    }

    &__title {
      padding: 160px 0 40px;
    }

    &__arrow {
      &_left {
        left: calc((100vw - 35vw) / 2 - 60px);
      }

      &_right {
        right: calc((100vw - 35vw) / 2 - 60px);
      }
    }
  }
}

@include respond-up('large') {
  .index-works {
    &__main {
      opacity: 0;
      transform: translate3d(0, 30px, 0);
    }
    &__text {
      height: 106px;
      padding: 22px 34px;
      font-size: 20px;
    }
    &__items {

    }
  }
}

@include respond('large') {
  .index-works {
    &__item {
      height: 26vw;
    }

    &__title {
      padding: 160px 0 30px;
    }
  }
}

@include respond('medium') {
  .index-works {
    &__item {
      height: 42vw;
    }

    &__title {
      padding: 120px 0 20px;
    }

    &__text {
      height: 88px;
      padding: 16px 22px;
      font-size: 17px;
    }
  }
}

@include respond-down('medium') {
  .index-works {
    &__arrow {
      display: none;
    }
  }
}

@include respond-down('small') {
  .index-works {
    &__item {
      height: 46vw;
    }

    &__title {
      padding: 60px 0 12px;
    }

    &__text {
      height: 50px;
      padding: 10px 16px;
      font-size: 15px;
    }
  }
}