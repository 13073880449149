.prod-slider {
  &__main {
    position: relative;
  }

  &__items {

  }

  &__item {

  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__arrows {

  }

  &__left {

  }

  &__right {

  }

  &__wrap {
    position: absolute;
  }

  &__frame {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__leaf {
    position: absolute;
  }
}

@include respond-up('x-large') {
  .prod-slider {
    &__items {
      height: 456px;
    }

    &__wrap {
      top: 90px;
      right: -110px;
      bottom: -95px;
      left: 110px;
    }

    &__leaf {
      left: 170px;
      bottom: -20px;
    }
  }
}

@include respond-up('large') {
  .prod-slider {
    &__items {
      height: 25vw;
    }

    &__wrap {
      outline: 25px solid $magnolia-color;
      outline-offset: -25px;
    }
  }
}

@include respond('large') {
  .prod-slider {
    &__items {
      height: 25vw;
    }

    &__wrap {
      top: 90px;
      right: -85px;
      bottom: -80px;
      left: 110px;
    }

    &__leaf {
      left: 120px;
      bottom: -22px;
    }
  }
}

@include respond-up('medium') {
  .prod-slider {
    &__items {
      z-index: 5;
    }

    &__arrows {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
}

@include respond('medium') {
  .prod-slider {
    &__main {
      margin-bottom: 124px;
    }

    &__wrap {
      top: 125px;
      right: -70px;
      bottom: -76px;
      left: 170px;
      outline: 20px solid $magnolia-color;
      outline-offset: -20px;
    }

    &__leaf {
      left: 325px;
      bottom: -25px;
    }
  }
}

@include respond-down('medium') {
  .prod-slider {
    &__items {
      height: 56vw;
    }
  }
}

@include respond-down('small') {
  .prod-slider {
    &__main {
      margin-bottom: 28px;
    }

    &__arrows {
      margin-top: 4px;
    }

    &__wrap {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    &__leaf {
      height: 55px;
      right: -62px;
      bottom: -40px;
    }
  }
}