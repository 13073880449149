.default-form {
  &__form {
    position: relative;

    &.success {
      .default-form {
        &__data {
          opacity: 0;
        }

        &__success {
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
          transform: translate3d(0,0,0);
        }
      }
    }
  }

  &__data {
    transition: opacity $default-transition;
  }

  &__title {
    font-weight: 800;
    letter-spacing: 0;
    line-height: 1;
  }

  &__description {
    color: $gray-color;
    letter-spacing: 0;
    max-width: 430px;
  }

  &__wrap {

  }

  &__fields {
    .form-field {
      margin: 0;
      height: 100%;

      label {
        display: none;
      }

      input {
        position: relative;
        height: 100%;
        border: 2px solid #ECECE3;
        background: {
          color: #F6F6F1;
        };
      }
    }

    .form-field.phone {
      input {
        background: #F6F6F1 url('../svg/phone-form.svg') no-repeat;
      }
    }
  }

  &__buttons {

  }

  &__button {
    letter-spacing: 0.115em;
  }

  &__policy {
    font-size: 13px;
    color: $gray-color;
    letter-spacing: 0;
    line-height: 1.46;
  }

  &__link {
    color: $green-color;
    position: relative;

    &:hover {
      &::before {
        transform-origin: left top;
        transform: scaleX(1);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid $green-color;
      opacity: 0.5;
      transform-origin: right top;
      transition: transform .5s ease-in-out;
      transform: scaleX(0);
    }
  }

  &__success {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition: {
      property: opacity, transform, visibility;
      duration: 0.4s;
    };
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate3d(0,40px,0);
  }

  &__success-image {
    height: 99px;
    width: 99px;
    background: {
      position: 50% 50%;
      size: contain;
      repeat: no-repeat;
    };
  }

  &__success-container {
    display: flex;
    flex-direction: column;
  }

  &__success-title {
    font-weight: 800;
    color: #333531;
    letter-spacing: 0;
    line-height: 1;
    max-width: 489px;
  }

  &__success-description {
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
    line-height: 1.35;
    max-width: 334px;
  }
}

@include respond-up('medium') {
  .default-form {
    &__title {
      font-size: 35px;
      padding-bottom: 30px;
    }

    &__description {
      font-size: 15px;
      padding-bottom: 35px;
    }

    &__wrap {
      display: flex;
      max-width: 562px;
      padding-bottom: 20px;
    }

    &__fields {
      flex: 1;

      .form-field {
        input {
          padding: 0 10px 0 48px;
          border-right: none;
        }
      }

      .form-field.phone {
        input {
          background-position: 16px center;
        }
      }
    }

    &__button {
      padding: 20px 34px 18px;
    }

    &__success-image {
      height: 99px;
      width: 99px;
      margin-right: 45px;
    }

    &__success-container {

    }

    &__success-title {
      font-size: 45px;
      margin-bottom: 30px;
      max-width: 489px;
    }

    &__success-description {
      font-size: 16px;
      max-width: 334px;
    }
  }
}

@include respond-down('small') {
  .default-form {
    &__title {
      font-size: 25px;
      padding-bottom: 20px;
    }

    &__description {
      font-size: 15px;
      padding-bottom: 26px;
    }

    &__wrap {
      padding-bottom: 24px;
    }

    &__fields {
      .form-field {
        input {
          height: 46px;
          padding: 0 6px 0 42px;
          border-bottom: none;
        }
      }

      .form-field.phone {
        input {
          background-position: 12px center;
        }
      }
    }

    &__button {
      width: 100%;
      padding: 16px 10px 14px;
    }

    &__success {
      flex-direction: column;
    }

    &__success-image {
      margin-bottom: 22px;
    }

    &__success-title {
      font-size: 30px;
      margin-bottom: 10px;
    }

    &__success-description {
      font-size: 15px;
    }
  }
}