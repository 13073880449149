.material-group {
  position: relative;
  background-color: #F6F6F1;
  font-size: 16px;
  border: 2px solid transparent;
  transition: border .4s ease-in-out;

  &:hover {
    border: 2px solid #ECECE3;
  }


  &__title {
    display: block;
    width: 60px;
    font-size: 16px;
    padding: 16px;
    line-height: 1em;
  }
  &.active {
    border: 2px solid #CEAF72;
    color: #CEAF72;
  }
  &__checkmark {
    position: absolute;
    top: -7px;
    right: -7px;
    opacity: 0;
    transition: opacity .4s ease-in-out;
  }
  &.active &__checkmark {
    opacity: 1;
  }
  &__image {
    position: absolute;
    background: {
      position: right;
      size: contain;
      repeat: no-repeat;
    }
    width: 63px;
    height: 60px;
    z-index: 0;
    top: 10px;
    right: 0;
  }
}

@include respond-up('x-large') {
  .material-group {
    width: 306px;
    height: 82px;
    margin: 0 10px;
    margin-bottom: 57px;
  }
}

@include respond('large') {
  .material-group {
    width: 300px;
    height: 82px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
  }
}

@include respond('medium') {
  .material-group {
    width: 332px;
    height: 82px;
    margin: 0 8px 16px 8px
  }
}

@include respond-down('small') {
  .material-group {
    width: 100%;
    margin-bottom: 14px;
    &__image {
      height: 45px;
    }
  }
}

