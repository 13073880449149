.credits {
  &__link {
    display: block;
  }

  &__item {
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__img {
    display: block;
  }
}

@include respond-up('x-large') {
  .credits {
    &__main {
      padding-bottom: 200px;
    }

    &__item {
      padding-bottom: 36px;
    }
  }
}

@include respond('large') {
  .credits {
    &__main {
      padding-bottom: 144px;
    }

    &__item {
      padding-bottom: 48px;
    }
  }
}

@include respond-up('medium') {
  .credits {
    &__img {
      &_mobile {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .credits {
    &__main {
      padding-bottom: 136px;
    }

    &__item {
      padding-bottom: 22px;
    }
  }
}

@include respond-down('small') {
  .credits {
    &__main {
      padding-bottom: 62px;
    }

    &__item {
      padding-bottom: 18px;
    }

    &__img {
      &_desktop {
        display: none;
      }
    }
  }
}