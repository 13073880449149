.pellet-about {

  &__info {
    display: flex;
    flex-direction: column;

    &_small {
      display: none;
    }

    &::before {
      content: '';
      display: block;
      height: 4px;
      width: 64px;
      background-color: $green-color;
    }
  }

  &__info-title {
    font-weight: 800;
    color: $black-color;
    letter-spacing: 0;
  }

  &__info-description-container {

  }

  &__info-description-numbers {
    font-weight: 800;
    color: $primary-color;
    letter-spacing: 0;
  }

  &__info-description-title {
    font-weight: bold;
    color:$black-color;
    letter-spacing: 0;
    line-height: 1.4;
  }

  &__info-description {
    color: $gray-color;
    letter-spacing: 0;
    line-height: 1.6;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__info-container {
    flex: 0 0 35%;
  }

  &__advantages {
    display: flex;
    flex-wrap: wrap;
  }

  &__advantage-container {
    flex: 0 0 50%;
  }

  &__advantage {

  }

  &__advantage-count {
    font-weight: 800;
    color: $green-color;
    letter-spacing: 0;
  }

  &__advantage-text {
    color: $black-color;
    letter-spacing: 0;
    line-height: 1.2;
    font-weight: 400;
  }

  &__images {
    flex: 0 0 53%;
    position: relative;
  }

  &__images-border {
    display: block;
    position: absolute;
  }

  &__image {
    position: absolute;
    &_large {

    }

    &_small {

    }
  }
}

@include respond-up('x-large') {
  .pellet-about {

    &__info {
      display: flex;
      flex-direction: column;

      &_small {
        display: none;
      }

    }

    &__info-title {
      font-size: 55px;
      margin-bottom: 63px;
      max-width: 480px;
    }

    &__info-description-container {
      display: flex;
      flex-direction: column;
    }

    &__info-description-numbers {
      font-size: 70px;
      margin-bottom: 28px;
    }

    &__info-description-title {
      font-size: 26px;
      margin-bottom: 16px;
    }

    &__info-description {
      font-size: 17px;
    }

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__info-container {
      flex: 0 0 42%;
    }

    &__advantages {

    }

    &__advantage-container {

    }

    &__advantage {

    }

    &__advantage-count {

    }

    &__advantage-text {

    }

    &__images {
      flex: 0 0 51%;
    }

    &__images-border {
      border: 25px solid $magnolia-color;
      top: 108px;
      height: 526px;
      width: 585px;
    }

    &__image {
      z-index: 2;
      &_large {
        top: 33px;
        right: 0;
        height: 482px;
        width: 645px;
      }

      &_small {
        bottom: 0;
        right: 48px;
        height: 296px;
        width: 395px;
      }
    }
  }
}

@include respond-up('large') {
  .pellet-about {

    &__info {
      display: flex;
      flex-direction: column;

      &_small {
        display: none;
      }

      &::before {
        margin-bottom: 48px;
      }
    }

    &__info-title {
      font-size: 45px;
      margin-bottom: 55px;
      max-width: 420px;
    }

    &__info-description-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 61px;
    }

    &__info-description-numbers {
      font-size: 70px;
      margin-bottom: 5px;
    }

    &__info-description-title {
      font-size: 23px;
      margin-bottom: 21px;
    }

    &__info-description {
      font-size: 17px;
      max-width: 313px;
    }

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__info-container {
      flex: 0 0 42%;
    }

    &__advantages {
      margin: 0 -48px;
    }

    &__advantage-container {
      padding: 0 48px 42px;
    }

    &__advantage {

    }

    &__advantage-count {
      font-size: 60px;
      margin-bottom: 11px;
    }

    &__advantage-text {
      font-size: 21px;
    }

    &__images {
      flex: 0 0 51%;
    }
  }
}

@include respond('large') {
  .pellet-about {
    &__images-border {
      top: 100px;
      height: 448px;
      width: 485px;
      border: 25px solid $magnolia-color;
    }

    &__image {
      z-index: 2;
      &_large {
        top: 20px;
        right: 0;
        height: 386px;
        width: 516px;
      }

      &_small {
        bottom: 184px;
        right: 48px;
        height: 237px;
        width: 316px;
      }
    }
  }
}

@include respond('medium') {
  .pellet-about {
    &__info {
      display: flex;
      flex-direction: column;

      &_small {
        display: none;
      }

      &::before {
        margin-bottom: 34px;
      }
    }

    &__info-title {
      font-size: 38px;
      margin-bottom: 90px;
      max-width: 354px;
    }

    &__info-description-container {
      display: flex;
      flex-direction: column;
      max-width: 327px;
      margin-bottom: 90px;
    }

    &__info-description-numbers {
      font-size: 50px;
      line-height: 1.8;
    }

    &__info-description-title {
      font-size: 23px;
      margin-bottom: 11px;
    }

    &__info-description {
      font-size: 16px;
    }

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__info-container {
      flex: 0 0 53%;
    }

    &__advantages {
      margin: 0 -23px;
    }

    &__advantage-container {
      padding: 0 23px 43px;
    }

    &__advantage {

    }

    &__advantage-count {
      font-size: 50px;
      margin-bottom: 11px;
    }

    &__advantage-text {
      font-size: 18px;
    }

    &__images {
      overflow: hidden;
      flex: 0 0 51%;
      margin-right: -44px;
    }

    &__images-border {
      border: 21px solid $magnolia-color;
      top: 42px;
      right: -62px;
      height: 32vw;
      width: 40vw;
    }

    &__image {
      z-index: 2;
      &_large {
        top: 0;
        right: 0;
        height: 245px;
        width: 42.6vw;
      }

      &_small {
        max-width: 379px;
        bottom: 180px;
        top: 39vw;
        right: 0;
        height: 197px;
        width: 31.6vw;
      }
    }
  }
}

@include respond-down('small') {
  .pellet-about {
    &__info {
      display: none;
      flex-direction: column;

      &_small {
        display: flex;
      }

      &::before {
        display: none;
      }
    }

    &__info-title {
      font-size: 25px;
      margin-bottom: 25px;
    }

    &__info-description-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 42px;
    }

    &__info-description-numbers {
      font-size: 50px;
      line-height: 1.8;
      margin-bottom: 8px;
    }

    &__info-description-title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__info-description {
      font-size: 16px;
    }

    &__container {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }

    &__info-container {
      flex: 1 1 100%;
    }

    &__advantages {
      margin: 0 -23px;
    }

    &__advantage-container {
      padding: 0 23px 43px;
    }

    &__advantage {

    }

    &__advantage-count {
      font-size: 40px;
      margin-bottom: 4px;
    }

    &__advantage-text {
      font-size: 16px;
    }

    &__images {
      overflow: hidden;
      flex: 1 1 100%;
      min-height: 147vw;
      margin-right: -22px;
      margin-bottom: 67px;
    }

    &__images-border {
      border: 21px solid $magnolia-color;
      top: 90px;
      right: -68px;
      left: 50px;
      height: 60vw;
      width: 100%;
    }

    &__image {
      z-index: 2;
      &_large {
        top: 0;
        right: 22px;
        left: 0;
        height: 245px;
      }

      &_small {
        bottom: 180px;
        top: 89vw;
        left: 32px;
        right: 54px;
        height: 197px;
      }
    }
  }
}