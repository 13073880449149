.roof {
  position: relative;
  &__title {
    color: rgba(51, 53, 49, 0.5);
    font-size: 17px;
    margin-bottom: 22px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

}

@include respond-up('x-large') {
  .roof {
    &__list {
      margin-left: -10px;
    }
  }
};

@include respond('large') {
  .roof {
    &__list {
      margin-left: -8px;
    }
  }
};

@include respond('medium') {
  .roof {
    &__list {
      margin-left: -8px;
    }
  }
}

@include respond-down('small') {
  .roof {
    &__list {
      display: block;
    }
  }
}