.foundation {
  margin-bottom: 57px;
  &__title {
    font-size: 17px;
    color: rgba(51, 53, 49, 0.5);
    margin-bottom: 22px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }
}

@include respond-up('x-large') {
  .foundation {
    &__list {
      margin-left: -10px;
    }
  }
}

@include respond('large') {
  .foundation {
    &__list {
      margin-left: -8px;
    }
  }
}

@include respond-down('small') {
  .foundation {
    &__list {
      display: block;
    }
  }
}