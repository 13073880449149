.index-houses {
  position: relative;

  &.index-houses_small-forms {
    margin-bottom: 0;
  }

  &__info {

  }

  &__line {
  }

  &__title {
    font-weight: 800;
  }

  &__item-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__description {

  }

  &__slider {
    position: relative;
  }

  &__arrows {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 5;
  }

  &__item {
    background: {
      size: cover;
      position: center;
    };
    height: 100%;
  }

  &__item-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }


  &__item-info {
    color: #FFFFFF;
  }
}


@include respond-up('x-large') {
  .index-houses {
    margin-bottom: 233px;

    &_bathrooms {
      .index-houses__slider {
        position: relative;
        height: 28.1vw;
      }

      .index-houses__square {
        position: absolute;
        content: '';
        top: -88px;
        right: 0;
        z-index: -1;
        width: 746px;
        height: 419px;
        border-left: 25px solid #F6F6F1;
        border-top: 25px solid #F6F6F1;
        border-bottom: 25px solid #F6F6F1;;
      }
    }

    &_small-forms {
      padding-left: 220px;

      .index-houses__slider {
        height: 24.16vw;
      }

      .index-houses__square {
        position: absolute;
        content: '';
        top: -88px;
        left: 100px;
        z-index: -1;
        width: 746px;
        height: 419px;
        border: 25px solid #F6F6F1;
      }
    }

    &__slider {
      height: 30.57vw;

    }

    &__list {
      height: 100%;
    }

    &__info {

    }

    &__info-inner {
      width: 342px;
    }

    &__line {

    }

    &__title {
      font-size: 55px;
      line-height: 1em;
    }

    &__description {
      margin-bottom: 64px;
    }
  }
}

@include respond-up('large') {
  .index-houses {
    display: flex;

    &_bathrooms {
      padding-right: 70px;

      .index-houses__info {
        order: 1
      }

      .index-houses__slider {
        order: 2;
        transform: translate3d(245px, 0, 0);
        opacity: 0;
      }

      .index-houses__info-left {
        transform: translate3d(0, 57px, 0);
        opacity: 0;
      }

      .index-houses__info-right {
        transform: translate3d(0, 57px, 0);
        opacity: 0;
      }
    }

    &_small-forms {
      padding-left: 150px;
    }

    &__info {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__item-info {
      position: absolute;
      bottom: 0;
      padding: 61px 202px 17px 28px;
    }

    &__info-inner {
      width: 342px;
    }

    &__slider {
      width: 50%;
    }

    &__list {
      width: 100%;
      height: 100%;
    }

    &__info {
      width: 50%;
    }

    &__line {
      margin-bottom: 41px;
    }

    &__title {
      margin-bottom: 33px;
      font-size: 45px;
    }


  }
  .index-houses_houses {
    .index-houses__slider {
      transform: translate3d(-245px, 0, 0);
      opacity: 0;
    }

    .index-houses__info-left {
      transform: translate3d(0, 57px, 0);
      opacity: 0;
    }

    .index-houses__info-right {
      transform: translate3d(0, 57px, 0);
      opacity: 0;
    }
  }
  .index-houses_bathrooms {

  }
  .index-houses_small-forms {
    .index-houses__slider {
      transform: translate3d(-245px, 0, 0);
      opacity: 0;
    }

    .index-houses__info-left {
      transform: translate3d(0, 57px, 0);
      opacity: 0;
    }

    .index-houses__info-right {
      transform: translate3d(0, 57px, 0);
      opacity: 0;
    }
  }
}

@include respond-up('medium') {
  .index-houses {
    &__line {
      display: block;
      height: 4px;
      width: 64px;
      background-color: $green-color;
    }

    //&__item-label {
    //  position: absolute;
    //  bottom: 26px;
    //  left: 34px;
    //}
    //
    //&__item-sizes {
    //  position: absolute;
    //  bottom: 26px;
    //  right: 200px;
    //}
    &__item-info {
      position: absolute;
      display: flex;
      width: 100%;
      justify-content: space-between;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
      font-weight: bold;
    }

    &__item-size {
      margin-right: 13px;
    }

    &__item-size-icon {
      margin-right: 11px;
    }

    &__item-size-label {
      position: relative;
      top: 2px;
    }
  }
}

@include respond('large') {
  .index-houses {
    &_bathrooms {
      .index-houses__slider {
        height: 28.6vw;
      }
      .index-houses__square {
        position: absolute;
        content: '';
        top: -88px;
        right: 0px;
        z-index: -1;
        width: 555px;
        height: 326px;
        border-left: 25px solid #F6F6F1;
        border-top: 25px solid #F6F6F1;
        border-bottom: 25px solid #F6F6F1;;
      }
    }

    &_small-forms {
      .index-houses__slider {
        height: 25.93vw;
      }
    }

    margin-bottom: 225px;

    &__description {
      margin-bottom: 57px;
    }

    &__slider {
      height: 31.46vw;
    }
  }
}

@include respond('medium') {
  .index-houses {
    margin-bottom: 123px;

    &__slider {
      width: calc(100% - 44px);
      height: 56vw;
      margin-bottom: 48px;
    }

    &__item-info {
      position: absolute;
      bottom: 0;
      padding: 60px 190px 11px 28px;
    }

    &__list {
      height: 100%;
    }

    &__info {
      padding: 0 44px;
    }

    &__info-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info-left {
      max-width: 60%;
    }

    &__line {
      margin-bottom: 34px;
    }

    &__title {
      margin-bottom: 16px;
    }
  }
}

@include respond-down('small') {
  .index-houses {
    margin-bottom: 53px;
    padding: 0 22px;

    &__slider {
      width: 100%;
      height: 49vw;
      margin-bottom: 64px;
    }

    &__title-mobile {
      display: block;
    }

    &__list {
      height: 100%;
    }

    &__info {

    }

    &__title {
      display: none;
    }

    &__head {
      background-color: #F6F6F1;
    }

    &__head-title {
      font-size: 23px;
      font-weight: 800;
      text-align: center;
      padding: 16px 0;
    }

    &__description {
      display: none;
    }

    &__info-right {
      text-align: center;
    }

    &__button {
      font-size: 12px;
    }

    &__arrows {
      bottom: -52px;
      left: 50%;
      transform: translateX(-50%);
    }

    &__button {
      font-size: 12px;
    }

    &__item-info {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .index-houses {
    &__head {
      display: none;
    }

    &__item-sizes {
      display: flex;
    }

    &__item-size {
      display: flex;
      align-items: center;
    }
  }
}


