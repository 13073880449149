.pellet-also {
  &__title {
    font-weight: 700;
    color: $black-color;
    letter-spacing: 0;
  }

  &__list {
    display: flex;
  }

  &__item-container {

  }

  &__item {
    background-color: $magnolia-color;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  &__item-name {
    font-weight: bold;
    color: #CEAF72;
    letter-spacing: 0;
    display: block;
    line-height: 1;
  }

  &__item-image {
    display: block;
    background: {
      size: contain;
      repeat: no-repeat;
      position: right 50%;
    };
  }
}

@include respond-up('large') {
  .pellet-also {
    &__title {
      font-size: 25px;
      margin-bottom: 24px;
    }

    &__list {
      margin: 0 -7px;
    }

    &__item-container {
      flex: 0 0 25%;
      padding: 0 7px;
    }

    &__item {
      min-height: 85px;
    }

    &__item-name {
      font-size: 16px;
      padding: 18px 21px;
      max-width: 200px;
    }

    &__item-image {
      height: 100%;
      width: 26%;
    }
  }
}

@include respond('medium') {
  .pellet-also {
    &__title {
      font-size: 25px;
      margin-bottom: 24px;
    }

    &__list {
      margin: 0 -8px -16px;
      flex-wrap: wrap;
    }

    &__item-container {
      flex: 0 0 50%;
      padding: 0 8px 16px;
    }

    &__item {
      min-height: 85px;
    }

    &__item-name {
      font-size: 16px;
      padding: 18px 21px;
      max-width: 161px;
    }

    &__item-image {
      height: 85px;
      width: 96px;
    }
  }
}

@include respond('small') {
  .pellet-also {
    &__title {
      font-size: 25px;
      margin-bottom: 24px;
    }

    &__list {
      margin-bottom: -14px;
      flex-wrap: wrap;
    }

    &__item-container {
      flex: 1 1 100%;
      padding-bottom: 14px;
    }

    &__item {
      min-height: 85px;
    }

    &__item-name {
      font-size: 16px;
      padding: 18px 21px;
      max-width: 161px;
    }

    &__item-image {
      height: 85px;
      width: 96px;
    }
  }
}