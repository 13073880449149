.button {
  padding: 17px 28px;
  margin: 0;
  text-align: center;
  transition: $default-transition;
  display: inline-block;
  border: none;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0.15em;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }

  &_green {
    color: #FFFFFF;
    background: $green-color;
    &:hover {
      background: darken($green-color, 7);
    }
  }

  &_block {
    display: block;
  }

  &_primary-outline {
    color: $primary-color;
    border: 2px solid $primary-color;
    background: transparent;
    &:hover {
      background: $primary-color;
      color: #FFFFFF;
    }
  }

  &_green-outline {
    color: $green-color;
    border: 2px solid $green-color;
    background: transparent;
    &:hover {
      background: $green-color;
      color: #FFFFFF;
    }
  }
}