.production-item {
  display: flex;
  height: 100%;
  flex-direction: column;
  background: {
    size: 250px 250px;
    repeat: no-repeat;
    color: #F6F6F1;
    position: bottom right;
  };

  &__name {
    font-weight: bold;
    color: #333531;
    letter-spacing: 0;
  }

  &__short-description {
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
    line-height: 1.3;
  }

  &__button {
    padding: 17px 43px;
  }
}

@include respond-up('x-large') {
  .production-item {
    padding: 58px 70px 85px;
    &__name {
      font-size: 32px;
      max-width: 403px;
    }

    &__short-description {
      font-size: 17px;
      max-width: 383px;
    }

    &__button {

    }
  }
}

@include respond-up('large') {
  .production-item {
    padding: 57px 53px 85px;
    &__name {
      font-size: 28px;
      margin-bottom: 24px;
      max-width: 300px;
    }

    &__short-description {
      font-size: 16px;
      margin-bottom: 50px;
      max-width: 293px;
    }

    &__button {
      max-width: 185px;
    }
  }
}

@include respond('medium') {
  .production-item {
    background-position: center right;
    padding: 52px 40px 55px;
    &__name {
      font-size: 28px;
      max-width: 360px;
      margin-bottom: 13px;
    }

    &__short-description {
      font-size: 16px;
      max-width: 325px;
      margin-bottom: 48px;
    }

    &__button {
      max-width: 185px;
    }
  }
}

@include respond-down('small') {
  .production-item {
    background-position: 100% 82%;
    background-size: 140px 140px;
    padding: 26px 22px 29px;
    &__name {
      font-size: 19px;
      max-width: 196px;
      margin-bottom: 42px;
    }

    &__short-description {
      display: none;
    }

    &__button {
      max-width: 135px;
      height: 46px;
      padding: 17px 20px;
      font-size: 12px;
    }
  }
}