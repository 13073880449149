.advantage {
  &__main {
    display: flex;
    flex-direction: column;
  }

  &__icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 180px;
  }

  &__icon {

  }

  &__info {

  }

  &__name {
    font-weight: 700;
    font-size: 23px;
    line-height: 1;
    letter-spacing: 0;
    text-align: center;
  }

  &__description {
    color: $gray-color;
    letter-spacing: 0;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@include respond-up('x-large') {
  .advantage {
    &__name {
      font-size: 26px;
    }

    &__description {
      padding-top: 23px;
      font-size: 17px;
      line-height: 1.58;
    }
  }
}

@include respond('large') {
  .advantage {
    &__description {
      padding-top: 21px;
    }
  }
}

@include respond-up('medium') {
  .advantage {
    &__info {
      padding-top: 22px;
    }
  }
}

@include respond('medium') {
  .advantage {
    &__description {
      padding-top: 15px;
    }
  }
}

@include respond-down('small') {
  .advantage {
    &__main {
      padding: 0 22px;
    }

    &__info {
      padding-top: 12px;
    }

    &__name {
      font-size: 20px;
    }

    &__description {
      padding-top: 10px;
    }
  }
}