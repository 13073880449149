.pellet-advantages {
  &__title {

  }

  &__list {

  }
}

@include respond-up('large') {
  .pellet-advantages {
    &__title {
      margin-bottom: 80px;
    }
  }
}

@include respond('medium') {
  .pellet-advantages {
    &__title {
      margin-bottom: 60px;
    }
  }
}

@include respond-down('small') {

}