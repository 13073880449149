.pagination-endless {
  &__button {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 17px 45px;
    max-width: 224px;
  }
}

@include respond-down('small') {
  .pagination-endless {
    &__button {
      padding: 17px 26px;
      max-width: 186px;
    }
  }
}