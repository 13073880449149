.range-input {
  &__values {
    display: flex;
    max-width: 330px;
    width: 100%;
  }

  &__value {
    flex: 0 0 50%;
    position: relative;

    &_from {
      input[type='text'] {
        padding-left: 38px;
      }
    }

    &_to {
      input[type='text'] {
        padding-left: 42px;
      }
    }

    &:last-child {
      input[type='text'] {
        border-right: 2px solid #ECECE3;
      }
    }

    input[type='text'] {
      font-family: $font;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.375;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background: $magnolia-color;
      border: 2px solid #ECECE3;
      height: 53px;
      border-right: none;
      border-bottom: 3px solid #ECECE3;

      &:focus + span {
        .range-input {
          &__postfix {
            display: none;
          }
        }
      }
    }
  }

  &__text {
    position: absolute;
    top: 0;
    left: 18px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    pointer-events: none;
  }

  &__label {

  }

  &__input {

  }

  &__show {
    color: $t;
  }

  &__prefix {
    color: $gray-color;
    font-weight: 400;
  }

  &__postfix {
    margin-top: -2px;
  }

  &__sup {
    font-size: 10px;
    vertical-align: super;
  }

  &__line {
    width: 80%;
    margin: 0 auto;
    transform: translateY(-3px);

    &.noUi-target {
      background-color: #ECECE3;
    }

    &.noUi-horizontal {
      height: 3px;
      border-radius: 0;
      border: none;
      box-shadow: none;

      .noUi-connect {
        background: $green-color;
      }

      .noUi-handle {
        right: -11px !important;
        top: -10px;
        height: 22px;
        width: 22px;
        background: $green-color;
        box-shadow: 0 2px 7px 0 rgba(110,126,100,0.26);
        border: 6px solid #fff;
        border-radius: 100px;
        cursor: grab;
        transition: transform $default-transition;

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          cursor: grabbing;
        }

        &::after,
        &::before {
          display: none;
        }
      }
    }
  }
}