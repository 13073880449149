.bill-design {
  &__main {
    background-color: $magnolia-color;
    overflow: hidden;
  }

  &__text {

  }

  &__title {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1;
  }

  &__subtitle {
    color: $gray-color;
    letter-spacing: 0;
    line-height: 1.4;
  }

  &__button {
    width: 258px;
    padding: 17px 10px;
  }

  &__image {

  }

  &__img {

  }
}

@include respond-up('x-large') {
  .bill-design {
    &__main {
      padding: 54px 52px;
    }

    &__title {
      font-size: 34px;

      &::before {
        margin-bottom: 42px;
      }
    }

    &__subtitle {
      padding-top: 22px;
      font-size: 20px;
    }

    &__button {
      margin-top: 50px;
    }

    &__image {
      margin: 74px -160px -160px 0;
    }
  }
}

@include respond-up('large') {
  .bill-design {
    height: 100%;
    padding: 0 15px 30px;

    &__main {
      height: 100%;
    }

    &__title {
      display: flex;
      flex-direction: column;

      &::before {
        content: '';
        border-bottom: 4px solid $green-color;
        width: 64px;
      }
    }
  }
}

@include respond('large') {
  .bill-design {
    &__main {
      padding: 42px 36px;
    }

    &__title {
      font-size: 30px;

      &::before {
        margin-bottom: 32px;
      }
    }

    &__subtitle {
      padding-top: 20px;
      font-size: 17px;
    }

    &__button {
      margin-top: 42px;
    }

    &__image {
      margin: 40px -100px -100px 0;
    }
  }
}

@include respond('medium') {
  .bill-design {
    padding: 0 10px 28px;

    &__main {
      padding: 60px 0 30px 40px;
      display: flex;
    }

    &__text {
      flex: 0 0 60%;
    }

    &__image {
      flex: 0 0 50%;
    }

    &__img {
      object-position: left top;
    }

    &__title {
      font-size: 28px;
    }

    &__subtitle {
      padding-top: 20px;
    }

    &__button {
      margin-top: 46px;
    }
  }
}

@include respond-down('small') {
  .bill-design {
    padding-bottom: 28px;

    &__main {
      padding: 34px 28px;
    }

    &__title {
      font-size: 22px;
    }

    &__image {
      margin-top: 40px;
      margin-right: -70px;
      margin-bottom: -100px;
    }

    &__img {
      object-position: left top;
    }

    &__subtitle {
      padding-top: 14px;
      font-size: 15px;
    }

    &__button {
      margin-top: 28px;
      width: 232px;
      font-size: 12px;
      padding: 15px 10px 13px;
      letter-spacing: 0.1em;
    }
  }
}
