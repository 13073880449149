.wall-item {
  position: relative;

  margin-bottom: 16px;
  border: 2px solid transparent;
  transition: border .4s ease-in-out;

  &:hover {
    border: 2px solid #ECECE3;
  }

  &__link {
    display: inline-block;

    background-color: #F6F6F1;
  }
  &.active {
    border: 2px solid #CEAF72;
    color: #CEAF72;
  }
  &__icon {
    position: absolute;
    top: -5px;
    right: -5px;
    opacity: 0;
    transition: opacity .4s ease-in-out;
  }
  &.active &__icon {
    opacity: 1;
  }
}

@include respond-up('x-large') {
  .wall-item {
    margin-right: 8px;
    margin-left: 8px;
    &__link {
      padding: 17px 23px;
    }
  }
}

@include respond('large') {
  .wall-item {
    margin-right: 6px;
    margin-left: 6px;
    &__link {
      padding: 17px 12px;
    }
  }
}

@include respond-down('medium') {
  .wall-item {
    margin-left: 8px;
    margin-right: 8px;
    &__link {
      font-size: 15px;
      padding: 17px 24px;
    }
  }
}