.action-more {
  &__title {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
  }

  &__items {

  }

  &__item {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    display: flex;

    &:hover {
      .action-more {
        &__name {
          border-bottom: 1px solid $primary-color;
        }
      }
    }
  }

  &__name-wrap {
    padding: 18px 18px 16px 20px;
    background-color: $magnolia-color;
  }

  &__name {
    color: $primary-color;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0;
    border-bottom: 1px solid $t;
    transition: border-bottom-color $default-transition;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__image {
    flex: 0 0 100px;
  }

  &__img {
    display: block;
    object-fit: cover;
  }
}

@include respond-up('x-large') {
  .action-more {
    &__items {
      padding-top: 22px;
    }

    &__item {
      margin-bottom: 6px;
    }

    &__img {
      height: 96px;
    }
  }
}

@include respond('large') {
  .action-more {
    &__items {
      padding-top: 24px;
    }

    &__item {
      margin-bottom: 12px;
    }
  }
}

@include respond('medium') {
  .action-more {
    &__items {
      display: flex;
      padding-top: 24px;
      margin: 0 -8px;
    }

    &__item {
      flex: 0 0 50%;
      padding: 0 8px;
    }
  }
}

@include respond-down('large') {
  .action-more {
    &__items {
      padding-top: 18px;
    }

    &__item {
      margin-bottom: 12px;
    }

    &__name-wrap {
      flex: 1;
    }

    &__img {
      height: 86px;
    }
  }
}