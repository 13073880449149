.production-advantages {
  &__item {
    padding: 0 85px;
  }
}

@include respond-up('x-large') {
  .production-advantages {
    &__item {
      padding: 0 140px;
    }
  }
}

@include respond('large') {
  .production-advantages {
    &__item {
      padding: 0 85px;
    }
  }
}

@include respond('large') {
  .production-advantages {
    &__item {
      padding: 0 85px;
    }
  }
}
