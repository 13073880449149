.breadcrumbs {
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 0;
  }

  &__item {
    font-size: 14px;
    line-height: 1;
    color: $gray-color;
    letter-spacing: 0.02em;
    cursor: default;

    &_delimiter {
      padding: 0 12px;
    }
  }

  &__link {
    color: inherit;
    border-bottom: 1px solid $light-gray-color;
    transition: color $default-transition, border-bottom-color $default-transition;

    &:hover {
      color: $green-color;
      border-bottom: 1px solid rgba(119,177,86,.5);
    }
  }
}

@include respond-up('large') {
  .breadcrumbs {
    &__link {
      padding: 1px 0;
    }
  }
}