.empty {
  &__icon {
    display: flex;
    justify-content: center;
  }

  &__title {
    font-weight: bold;
    color: $black-color;
    letter-spacing: 0;
    text-align: center;
  }

  &__text {
    color: $gray-color;
    letter-spacing: 0;
    text-align: center;
  }

  &__phone {
    font-weight: 500;
    color: $black-color;
    white-space: nowrap;
  }
}

@include respond-up('large') {
  .empty {
    &__title {
      padding-top: 26px;
      font-size: 20px;
      line-height: 1.45;
    }

    &__text {
      max-width: 530px;
      margin: 0 auto;
      padding-top: 12px;
      font-size: 17px;
      line-height: 1.7;
    }
  }
}

@include respond-down('medium') {
  .empty {
    &__title {
      padding-top: 14px;
      font-size: 18px;
      line-height: 1.44;
    }

    &__text {
      padding-top: 14px;
      font-size: 15px;
      line-height: 1.6;
    }
  }
}