.order-block {
  &__main {

  }

  &__items {
    overflow-x: hidden;
  }

  &__item {
    display: flex;
    align-items: center;

    &:first-child {
      background-color: $magnolia-color;
    }

    &:last-child {
      overflow: hidden;
    }
  }

  &__text {

  }

  &__title {
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0;
    padding-bottom: 25px;
  }

  &__subtitle {
    color: $gray-color;
    letter-spacing: 0;
  }

  &__button {
    width: 258px;
    padding: 17px 10px;
  }

  &__image {

  }

  &__img {

  }
}

@include respond-up('x-large') {
  .order-block {
    &__main {
      padding-top: 120px;
    }

    &__items {
      height: 470px;
    }

    &__title {
      font-size: 32px;
      padding-bottom: 30px;
    }

    &__subtitle {
      font-size: 17px;
      padding-bottom: 60px;
    }

    &__item {
      &:first-child {
        padding-left: calc((100vw - 1480px) / 2);
      }

      &:last-child {
        padding-left: 110px;

        .order-block {
          &__image {
            transform: translateX(140px);
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .order-block {
    &__items {
      display: flex;
      border-top: 25px solid $magnolia-color;
      border-bottom: 25px solid $magnolia-color;
    }

    &__item {
      flex: 0 0 50%;


      &:first-child {
        transform: translate3d(-192px, 0, 0);
        opacity: 0;
        .order-block {
          &__image {
            padding-left: 40px;
          }
        }
      }
      &:nth-child(2) {
        transform: translate3d(192px, 0, 0);
        opacity: 0;
      }

    }
  }
}

@include respond('large') {
  .order-block {
    &__main {
      padding-top: 110px;
    }

    &__title {
      font-size: 28px;
      padding-bottom: 20px;
    }

    &__items {
      height: 405px;
    }

    &__item {
      &:first-child {
        padding-left: 150px;
      }

      &:last-child {
        padding-left: 80px;

        .order-block {
          &__image {
            transform: translateX(86px);
          }
        }
      }
    }

    &__subtitle {
      padding-bottom: 47px;
    }
  }
}

@include respond('medium') {
  .order-block {
    &__main {
      padding-top: 86px;
    }

    &__title {
      font-size: 28px;
    }

    &__subtitle {
      padding-bottom: 47px;
    }

    &__text {
      flex: 0 0 58%;
      padding-left: 44px;
    }

    &__item {
      padding: 60px 0 52px;
      justify-content: space-between;

      &:first-child {
        .order-block {
          &__image {
            padding-left: 60px;
          }

          &__img {
            max-height: 230px;
          }
        }
      }

      &:last-child {
        border-bottom: 20px solid $magnolia-color;

        .order-block {
          &__image {
            margin: -20px 0 -40px;
            flex: 0 0 450px;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .order-block {
    &__main {
      padding-top: 54px;
    }

    &__title {
      font-size: 22px;
      padding-bottom: 14px;
    }

    &__subtitle {
      font-size: 15px;
      padding-bottom: 29px;
    }

    &__button {
      width: 232px;
      font-size: 12px;
      padding: 15px 10px 13px;
      letter-spacing: 0.1em;
    }

    &__text {
      padding-left: 22px;
    }

    &__item {
      padding: 34px 0 30px;

      &:first-child {
        .order-block {
          &__img {
            padding-left: 10px;
            max-height: 230px;
          }
        }
      }

      &:last-child {
        border-bottom: 14px solid $magnolia-color;

        .order-block {
          &__image {
            flex: 0 0 46vw;
          }
        }
      }
    }

    &__image {
      align-self: flex-start;
    }
  }
}