.pellet-description {
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  &__info {
    &::before {
      content: '';
      height: 4px;
      width: 64px;
      background-color: $green-color;
      display: block;
    }
  }

  &__info-title {
    font-weight: 800;
    color: $black-color;
    letter-spacing: 0;
  }

  &__info-text {
    color: $gray-color;
    letter-spacing: 0;
    line-height: 1.7;
  }

  &__image-container {

  }

  &__image {
    background: {
      size: contain;
      repeat: no-repeat;
      position: 50% 50%;
    };
  }

  &__hidden {
    display: none;
  }
}

@include respond-up('large') {
  .pellet-description {
    padding: 90px 0 94px 90px;
    background-color: $magnolia-color;
    &__info {
      flex: 0 0 50%;

      &::before {
        margin-bottom: 49px;
      }
    }

    &__info-title {
      font-size: 45px;
      margin-bottom: 25px;
    }

    &__info-text {
      font-size: 17px;
    }

    &__image-container {
      flex: 0 0 50%;
    }

    &__image {
      height: 117%;
      width: 100%;

      &_large {
        display: block;
      }

      &_medium {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .pellet-description {
    padding: 0;
    margin-right: -44px;

    &__info {
      flex: 0 0 62%;

      &::before {
        margin-bottom: 34px;
      }
    }

    &__info-title {
      font-size: 38px;
      margin-bottom: 16px;
    }

    &__info-text {
      font-size: 16px;
    }

    &__image-container {
      flex: 0 0 38%;
    }

    &__image {
      height: 120%;
      width: 119%;

      &_large {
        display: none;
      }

      &_mesdium {
        display: block;
      }
    }
  }
}

@include respond-down('small') {
  .pellet-description {
    padding: 0;
    flex-direction: column-reverse;

    &__info {
      flex: 1 1 100%;

      &::before {
        display: none;
      }
    }

    &__info-title {
      font-size: 25px;
      margin-bottom: 9px;
    }

    &__info-text {
      font-size: 16px;
    }

    &__image-container {
      flex: 1 1 100%;
      margin-bottom: 22px;
    }

    &__image {
      height: 210px;
      width: 100%;

      &_large {
        display: none;
      }

      &_mesdium {
        display: block;
      }
    }
  }
}