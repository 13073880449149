.product-table {
  width: 100%;
  &__title-container {

  }

  &__title {
    text-align: left;
    font-weight: 700;
    color: $black-color;
    letter-spacing: 0;
  }

  &__line {

  }

  &__item {
    color: $black-color;
    letter-spacing: 0;
    vertical-align: middle;
  }

  &__hidden {
    display: none;
  }

  &__products-list {

  }

  &__products-item {

  }

  &__products-title {

  }

  &__products-descriptions {

  }

  &__products-description {

  }

  &__products-description-container {

  }

  &__products-description-title {

  }

  &__products-description-text {

  }
}

@include respond-up('large') {
  .product-table {
    &__title-container {

    }

    &__title {
      font-size: 20px;
      padding: 24px 0;

      &:nth-child(1) {
        padding: 20px 0 20px 20px;
      }
    }

    &__line {
      &:nth-child(2n) {
        background-color: $magnolia-color;
      }
    }

    &__item {
      font-weight: 500;
      font-size: 15px;
      padding: 20px 0;

      &:nth-child(1) {
        padding: 20px 0 20px 20px;
        max-width: 260px;
      }
    }

    &__products-list {
      display: none;
    }

    &__products-item {

    }

    &__products-title {

    }

    &__products-descriptions {

    }

    &__products-description {

    }

    &__products-description-container {

    }

    &__products-description-title {

    }

    &__products-description-text {

    }
  }
}

@include respond('medium') {
  .product-table {
    &__title-container {

    }

    &__title {
      font-size: 20px;
      padding: 24px 0;

      &:nth-child(1) {
        padding: 20px 0 20px 20px;
      }
    }

    &__line {
      &:nth-child(2n) {
        background-color: $magnolia-color;
      }
    }

    &__item {
      font-weight: 500;
      font-size: 15px;
      padding: 20px 0;

      &:nth-child(1) {
        padding: 20px 0 20px 20px;
      }
    }

    &__products-list {
      display: none;
    }

    &__products-item {

    }

    &__products-title {

    }

    &__products-descriptions {

    }

    &__products-description {

    }

    &__products-description-container {

    }

    &__products-description-title {

    }

    &__products-description-text {

    }
  }
}

@include respond-down('small') {
  .product-table {
    display: none;

    &_small {
      display: flex;
    }

    &__title-container {

    }

    &__title {
      font-size: 20px;
      padding: 24px 0;

      &:nth-child(1) {
        padding: 20px 0 20px 20px;
      }
    }

    &__line {
      &:nth-child(2n) {
        background-color: $magnolia-color;
      }
    }

    &__item {
      font-weight: 500;
      font-size: 15px;
      padding: 20px 0;

      &:nth-child(1) {
        padding: 20px 0 20px 20px;
      }
    }

    &__products-list {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__products-item {
      flex: 1 1 100%;
      background-color: $magnolia-color;
      padding: 24px 54px 24px 21px;
    }

    &__products-title {
      font-weight: 700;
      font-size: 17px;
      color: $black-color;
      letter-spacing: 0;
      margin-bottom: 27px;
    }

    &__products-descriptions {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px -20px;
    }

    &__products-description {
      flex: 0 0 50%;
      padding: 0 20px 20px;
    }

    &__products-description-container {

    }

    &__products-description-title {
      font-size: 16px;
      color: $gray-color;
      letter-spacing: 0;
      margin-bottom: 5px;
    }

    &__products-description-text {
      font-weight: 500;
      font-size: 16px;
      color: $black-color;
      letter-spacing: 0;
    }
  }
}