.title {
  color: $black-color;
  letter-spacing: 0;
  text-align: center;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &_big {
    font-weight: 800;
  }
  
  &_default {
    text-align: left;
    font-weight: 800;
  }
}

@include respond-up('x-large') {
  .title {
    font-size: 55px;

    &_big {
      font-size: 100px;
    }

    &_medium {
      font-size: 75px;
    }
  }
}

@include respond('large') {
  .title {
    font-size: 45px;

    &_big {
      font-size: 90px;
    }

    &_medium {
      font-size: 65px;
    }
  }
}

@include respond('medium') {
  .title {
    font-size: 38px;

    &_big {
      font-size: 54px;
    }

    &_medium {
      font-size: 44px;
    }
  }
}

@include respond-down('small') {
  .title {
    font-size: 23px;

    &_big {
      font-size: 35px;
      line-height: 1;
    }

    &_medium {
      font-size: 30px;
    }

    &_default {
      font-weight: 700;
      font-size: 25px;
    }
  }
}