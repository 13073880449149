.sort-input {
  &__link {
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1;
    transition: color $default-transition;
    padding-right: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:last-child {
      padding-right: 0;
    }

    &.active {
      color: $green-color;

      .sort-input {
        &__arrow {
          svg {
            filter: contrast(100%) brightness(100%);
          }
        }
      }
    }

    &_rotate {
      .sort-input {
        &__arrow {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__arrow {
    svg {
      transform: translateY(-1px);
      filter: contrast(0) brightness(60%);
      transition: transform $default-transition, filter $default-transition;
    }
  }
}