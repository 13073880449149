.recall {
  background: $magnolia-color;
  position: relative;
  overflow: hidden;
  &__wrapper {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__man {
    position: absolute;
    background: {
      size: contain;
      repeat: no-repeat;
      position: 50% 50%;
    };
  }

  &__form {

  }

  &__form-title {
    font-weight: 900;
    color: #333531;
    letter-spacing: 0;
  }

  &__form-subtitle {
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
  }

  &__button-container {
    display: flex;
  }

  &__date {
    label {
      display: none;
    }

    input[type="text"] {
      border: 2px solid #ECECE3;
      background: {
        color: $magnolia-color;
        image: url("../svg/phone-form.svg");
        repeat: no-repeat;
        position: 10px center;
      }
      padding-left: 40px;
    }

    .form-field.name {
      display: none;
    }
  }

  &__policy {

  }

  &__link {

  }

  &__success {
    display: flex;
    align-items: flex-start;
  }

  &__success-image {
    background: {
      position: 50% 50%;
      size: contain;
      repeat: no-repeat;
    };
  }

  &__success-container {
    display: flex;
    flex-direction: column;
  }

  &__success-title {
    font-weight: 900;
    color: #333531;
    letter-spacing: 0;
    line-height: 1;
  }

  &__success-description {
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
    line-height: 1.35;
  }
}

@include respond-up('large') {
  .recall {
    padding: 25px 0 25px 419px;
    &__wrapper {
      padding: 108px 0;
    }

    &__man {
      bottom: 0;
      top: 46px;
      left: 5%;
      width: 573px;
    }

    &__container {
      margin-left: 130px;
    }

    &__form {

    }

    &__form-title {
      margin-bottom: 23px;
      font-size: 65px;
    }

    &__form-subtitle {
      font-size: 30px;
      margin-bottom: 50px;
    }

    &__button-container {
      height: 53px;
      margin-bottom: 17px;
    }

    &__date {
      input[type="text"] {
        height: 53px;
        width: 338px;
        border: 2px solid #ECECE3;
      }
    }

    &__button {
      width: 224px;
      padding: 0;
    }

    &__policy {

    }

    &__link {

    }

    &__success-image {
      height: 99px;
      width: 99px;
      margin-right: 45px;
    }

    &__success-container {

    }

    &__success-title {
      font-size: 45px;
      margin-bottom: 30px;
      max-width: 489px;
    }

    &__success-description {
      font-size: 16px;
      max-width: 334px;
    }
  }

  .recall-index {
    .recall__man {
      transform: translate3d(-150px, 0, 0);
      opacity: 0;
    }
    .recall__container {
      transform: translate3d(183px, 0, 0);
      opacity: 0;
    }
  }
}

@include respond('medium') {
  .recall {
    padding: 20px 0 20px 310px;
    &__wrapper {
      padding: 74px 82px;
    }

    &__man {
      bottom: 0;
      top: 46px;
      left: -30%;
      width: 599px;
    }

    &__container {
      margin-left: 0;
    }

    &__form {
      .phone {
        margin-bottom: 0;
      }
    }

    &__form-title {
      margin-bottom: 28px;
      font-size: 48px;
      line-height: 1.1;
    }

    &__form-subtitle {
      font-size: 24px;
      margin-bottom: 42px;
    }

    &__button-container {
      height: auto;
      margin-bottom: 18px;
      flex-direction: column;
    }

    &__date {
      input[type="text"] {
        height: 53px;
        width: 292px;
        margin-bottom: 0;
      }
    }

    &__button {
      width: 292px;
      height: 53px;
      padding: 0;
    }

    &__policy {
      font-size: 13px;
    }

    &__link {

    }

    &__success {
      flex-direction: column;
    }

    &__success-image {
      height: 99px;
      width: 99px;
      margin-bottom: 29px;
    }

    &__success-container {

    }

    &__success-title {
      font-size: 40px;
      margin-bottom: 24px;
      max-width: 489px;
    }

    &__success-description {
      font-size: 16px;
      max-width: 334px;
    }
  }
}

@include respond-down('small') {
  .recall {
    padding: 15px 0;
    &__wrapper {
      padding: 50px 22px;
    }

    &__man {
      display: none;
    }

    &__container {
      margin-left: 0;
    }

    &__form {
      .phone {
        margin-bottom: 0;
      }
    }

    &__form-title {
      margin-bottom: 10px;
      font-size: 30px;
      line-height: 1.1;
    }

    &__form-subtitle {
      font-size: 15px;
      margin-bottom: 30px;
    }

    &__button-container {
      height: auto;
      margin-bottom: 21px;
      flex-direction: column;
    }

    &__date {
      flex: 1 1 100%;
      input[type="text"] {
        height: 53px;
        width: 100%;
        margin-bottom: 0;
      }
    }

    &__button {
      flex: 1 1 100%;
      min-height: 53px;
      height: 53px;
      padding: 0;
    }

    &__policy {
      font-size: 13px;
    }

    &__link {

    }

    &__success {
      flex-direction: column;
    }

    &__success-image {
      height: 99px;
      width: 99px;
      margin-bottom: 22px;
    }

    &__success-container {

    }

    &__success-title {
      font-size: 30px;
      margin-bottom: 10px;
      max-width: 489px;
    }

    &__success-description {
      font-size: 15px;
      max-width: 334px;
    }
  }
}