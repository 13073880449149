.preloader {
  pointer-events: none;

  &__icon {

  }

  &__line {

  }

  &__inner-line {

  }

  &__values {

  }

  &__number {

  }

  &__percent {

  }
}

@include respond-up('large') {
  .preloader {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    z-index: 3000;
    display: flex;
    align-items: center;
    justify-content: center;
    &__icon {
      margin-bottom: 25px;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__line {
      margin-bottom: 18px;
      width: 264px;
      height: 2px;
      background-color: #EDEAE3;
    }

    &__inner-line {
      width: 100%;
      height: 100%;
      background-color: #CEAF72;
      animation: lineloader .6s infinite;
    }

    &__values {
      font-size: 40px;
      font-weight: 500;
      color: #E3E3E3;
      white-space: nowrap;
      display: flex;
    }

    &__number {

    }

    &__percent {

    }
  }
}

@include respond-down('medium') {
  .preloader {
    display: none;
  }
}

@keyframes lineloader {
    0% {
      transform-origin: 0 100%;
      transform: scaleX(0);
    }
    49% {
      transform-origin: 0 100%;
      transform: scaleX(1);
    }
    50% {
      transform-origin: 100% 100%;
      transform: scaleX(1);

    }
    100% {
      transform-origin: 100% 100%;
      transform: scaleX(0);
    }

}