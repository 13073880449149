.project-card {
  flex: 0 0 33.33333%;
  padding: 0 15px 30px;

  &__image {
    display: block;
    position: relative;
    padding-top: 56%;
    overflow: hidden;

    &:hover {
      .project-card {
        &__img {
          transform: scale(1.08);
        }

        &__name {
          &_small {
            border-bottom: 1px solid #fff;
          }
        }
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    transition: transform .8s ease-in-out;
    height: 100%;
    width: 100%;
  }

  &__labels {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 9px 8px;
  }

  &__label {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    padding: 5px 6px 4px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: 8px;
  }

  &__info {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    align-items: flex-end;
    padding: 16px 12px 0 14px;

    svg {
      vertical-align: unset;
      margin-right: 6px;
      margin-bottom: -2px;
    }
  }

  &__name {
    &_small {
      font-weight: bold;
      font-size: 17px;
      color: #fff;
      letter-spacing: 0;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      border-bottom: 1px solid $t;
      transition: border .8s ease-in-out;
    }

    &_big {
      display: none;
      font-weight: 700;
      font-size: 17px;
      letter-spacing: 0;
    }
  }

  &__mask {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 56px;
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.75) 100%);
    padding: 16px 20px;
  }

  &__text {

  }

  &__area {
    padding-right: 16px;
  }

  &__sup {
    font-size: 10px;
    vertical-align: super;
  }

  &__perimeter {
    flex: 1;
  }

  &__new-price {
    color: $primary-color;
  }

  &__old-price {
    color: $gray-color;
    text-decoration: line-through;
  }

  &__price {

  }

  &__big-block {

  }
}

@include respond('medium') {
  .project-card {
    padding: 0 10px 28px;

    &__info {
      flex-wrap: wrap;
      padding: 14px 14px 0;
    }

    &__perimeter {
      flex: 0 0 50%;
    }

    &__price {
      padding-top: 10px;
    }
  }
}

@include respond-down('small') {
  .project-card {
    padding: 0 0 26px;
    flex: 0 0 100%;

    &__info {
      flex-wrap: wrap;
      padding: 14px 14px 0;
    }

    &__perimeter {
      flex: 0 0 50%;
    }

    &__price {
      padding-top: 8px;
    }

    &__label {
      font-size: 14px;
    }
  }
}