.about {
  position: relative;

  &__chip {
    position: absolute;
    right: 0;
  }

  &__leaf {
    position: absolute;
    left: 0;
  }

  &__descriptions {
    background-color: $magnolia-color;
  }

  &__description {

  }

  &__description-image {

  }

  &__description {
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before {
      content: '';
      display: block;
      height: 4px;
      background-color: $green-color;
    }
  }

  &__description-image {
    background: url('/static/images/about/house.png') no-repeat;
  }

  &__production,
  &__geography {
    overflow-x: hidden;
  }
}

@include respond-up('x-large') {
  .about {
    &__description {
      padding: 100px 40px 100px 108px;
      flex: 0 0 50%;
    }

    &__description-image {
      background-position: right bottom;
      background-size: contain;
    }

    &__chip {
      top: 170px;
    }
  }
}

@include respond-up('large') {
  .about {
    &__descriptions {
      display: flex;
    }

    &__description {

    }

    &__description-image {
      flex: 0 0 50%;
      margin-top: 36px;
    }

    &__leaf {
      top: 460px;
    }
  }
}

@include respond('large') {
  .about {

    &__descriptions {
      position: relative;
      overflow: hidden;
    }
    &__description {
      padding: 86px 737px 86px 68px;

    }

    &__description-image {
      position: absolute;
      min-height: 380px;
      min-width: 800px;
      right: 0;
      bottom: 0;
      background-position: center bottom;
      background-size: cover;
    }

    &__chip {
      top: 148px;
    }

    &__leaf {
      width: 110px;
    }
  }
}

@include respond-up('medium') {
  .about {
    &__description {
      font-size: 22px;
      line-height: 1.4;

      &::before {
        width: 64px;
        margin-bottom: 40px;
      }
    }
  }
}

@include respond('medium') {
  .about {
    &__description {
      padding: 85px 106px 10px 68px;
    }

    &__description-image {
      min-height: 328px;
      background-position: right bottom;
      background-size: contain;
    }

    &__geography {
      padding-top: 110px;
    }

    &__chip {
      width: 46px;
      top: -119px;
    }

    &__leaf {
      width: 114px;
      top: 340px;
    }
  }
}

@include respond-down('small') {
  .about {
    &__description {
      padding: 39px 26px 10px 30px;

      &::before {
        width: 42px;
        margin-bottom: 24px;
      }
    }

    &__description-image {
      min-height: 222px;
      max-width: 460px;
      margin-left: auto;
      background-position: 60% top;
      background-size: cover;
    }

    &__geography {
      padding-top: 60px;
    }

    &__chip {
      width: 32px;
      top: -97px;
    }

    &__leaf {
      width: 54px;
      top: 306px;
    }
  }
}

@media (min-width: 1366px) and (max-width: 1600px) {
  .about {
    &__description {
      padding: 85px 556px 10px 68px;
    }
    &__description-image {
      right: -200px;
    }
  }
}