.finish {
  &__title {
    font-weight: 800;

  }

  &__head {
    display: flex;
    align-items: center;
  }

  &__closer-label {
    color: $green-color;
  }

  &__list {

  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  &__item:nth-child(odd) {
    background-color: #F6F6F1;

  }

  &__item-label {
    font-weight: 500;
    line-height: 3.26em;
  }

  &__item-icon {

  }
}

@include respond-up('large') {
  .finish {
    &__head {
      margin-bottom: 60px;
    }
    &__closer {
      margin-right: 32px;
    }

    &__title {
      font-size: 28px;
    }

    &__list {
      width: 791px;
    }

    &__item {
      width: 100%;
    }

    &__item-label {
      font-size: 15px;
    }

    &__item-icon {

    }
  }
}

@include respond('medium') {
  .finish {
    &__head {
      margin-bottom: 60px;
    }
    &__closer-label {
      margin-right: 16px;
    }
    &__title {
      font-size: 28px;
    }
    &__list {
      width: 518px;
    }
  }
}

@include respond-down('small') {
  .finish {
    &__head {
      margin-bottom: 36px;
    }
    &__closer-icon {
      margin-right: 15px;
      position: relative;
      top: -1px;
    }
    &__closer-label {
      display: none;
    }
    &__title {
      font-size: 17px;

    }
    &__list {

    }
  }
}