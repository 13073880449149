.footer {
  &__container {
    display: flex;
    justify-content: space-between;
    padding: 102px 0 124px;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
  }

  &__phone {
    font-weight: bold;
    color: #333531;
    letter-spacing: 0;
    line-height: 1.6;
  }

  &__address {
    color: #333531;
    letter-spacing: 0;
    line-height: 1.6;
  }

  &__mail {
    font-weight: 500;
    color: $green-color;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__copy {
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
    line-height: 2;
  }

  &__policy {
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
    line-height: 2;
  }

  &__make {
    display: flex;
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
    line-height: 2;
  }

  &__make-link {
    color: $green-color;

    &:hover {
      .footer {
        &__make-link-text {
          transition: $default-transition;
          border-bottom: 1px solid $green-color;
        }
      }
    }
  }

  &__make-link-text {
    transition: $default-transition;
    border-bottom: 1px solid transparent;
  }

  &__list-container {
    display: flex;
  }

  &__list {
    display: flex;
  }

  &__column-container {
    &_medium {
      display: none;
    }

    &::before {
      content: '';
      display: block;
      height: 4px;
      width: 24px;
      background-color: $green-color;
      margin-bottom: 10px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__column-title {
    font-weight: bold;
    color: #333531;
    letter-spacing: 0;
    line-height: 1.7;

  }

  &__column-list {
    display: flex;
    flex-direction: column;
  }

  &__column-item-link {
    letter-spacing: 0;
    line-height: 1.4;
    transition: $default-transition;

    &:hover {
      transition: $default-transition;
      .footer {
        &__column-item-link-text {
          color: $green-color;
          transition: $default-transition;
          border-bottom: 1px solid $green-color;
        }
      }
    }
  }

  &__column-item-link-text {
    color: rgba(51,53,49,0.65);
    transition: $default-transition;
    border-bottom: 1px solid transparent;
  }
}

@include respond-up('large') {
  .footer {
    &__logo {
      margin-bottom: 45px;
    }

    &__info {
      margin-bottom: 48px;
    }

    &__phone {
      font-size: 20px;
    }

    &__address {
      font-size: 15px;
      margin-top: 6px;
      margin-bottom: 21px;
    }

    &__mail {
      font-size: 15px;
      border-bottom: 1px solid $t;
      transition: border $default-transition;

      &:hover {
        border-bottom: 1px solid $green-color;
      }
    }

    &__info {
      &_large {
        display: block;
      }

      &_medium {
        display: none;
      }
    }

    &__rule-container {
      flex-direction: column;
      font-size: 14px;
      &_large {
        display: flex;
      }

      &_medium {
        display: none;
      }
    }

    &__copy {
      color: rgba(51,53,49,0.65);
      letter-spacing: 0;
      line-height: 2;
    }

    &__make-link {
      padding-left: 5px;
    }

    &__column-container {
      margin-right: 67px;
      max-width: 233px;
      &_medium {

      }
    }

    &__column-title {
      font-size: 19px;
      margin-bottom: 35px;
    }

    &__column-item-link {
      font-size: 15px;
      display: block;
      padding: 7px 0;
    }
  }
  .footer-index {
    .footer__container {
      transform: translate3d(0, 50px, 0);
      opacity: 0;
    }
  }
}

@include respond('medium') {
  .footer {
    &__container {
      padding: 77px 0;
      flex-direction: column;
    }

    &__info-container {
      display: none;
    }

    &__logo {
      margin-bottom: 45px;
    }

    &__info {
      margin-bottom: 48px;
      margin-left: 0;
      &_large {
        display: none;
      }

      &_medium {
        display: flex;
      }
    }

    &__phone {
      font-size: 20px;
    }

    &__address {
      font-size: 15px;
      margin-top: 17px;
      margin-bottom: 23px;
    }

    &__mail {
      font-size: 15px;
    }

    &__info {
      &_large {
        display: none;
      }

      &_medium {
        display: block;
      }
    }

    &__rule-container {
      flex-direction: column;
      font-size: 14px;
      &_large {
        display: none;
      }

      &_medium {
        margin-top: 75px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__copy {
      color: rgba(51,53,49,0.65);
      letter-spacing: 0;
      line-height: 2;
    }

    &__make-link {
      padding-left: 5px;
    }

    &__list-container {

    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__column-container {
      flex: 0 0 50%;
      margin-bottom: 51px;

      &:nth-child(4) {
        order: 4;
      }

      &:nth-child(2) {
        order: 3;
      }

      &_medium {
        display: flex;
        flex-direction: column;
      }
    }

    &__column-title {
      font-size: 19px;
      margin-bottom: 35px;
    }

    &__column-item-link {
      font-size: 15px;
      display: block;
      padding: 7px 0;
    }
  }
}

@include respond-down('small') {
  .footer {

    &__container {
      padding: 43px 0 57px;
      flex-direction: column-reverse;
    }

    &__info-container {
      display: flex;
      margin-top: 10px;
    }

    &__info {
      margin-bottom: 38px;
      &_large {
        display: block;
      }

      &_medium {
        display: none;
      }
    }

    &__logo {
      display: none;
    }

    &__phone {
      font-size: 20px;
    }

    &__address {
      font-size: 15px;
      margin-top: 9px;
      margin-bottom: 21px;
    }

    &__mail {
      font-size: 15px;
    }

    &__rule-container {
      flex-direction: column;
      font-size: 14px;
      &_large {
        display: flex;
      }

      &_medium {
        display: none;
      }
    }

    &__copy {
      color: rgba(51,53,49,0.65);
      letter-spacing: 0;
      line-height: 2;
    }

    &__make-link {
      padding-left: 5px;
    }

    &__list-container {

    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__column-container {
      flex: 1 1 100%;
      margin-bottom: 27px;

      &_medium {
        display: none;
      }
    }

    &__column-title {
      font-size: 19px;
      margin-bottom: 9px;
    }

    &__column-item-link {
      font-size: 15px;
      display: block;
      padding: 7px 0;
    }
  }
}