.actions-block {
  &__items {
    height: 100%;
  }

  &__link {
    display: block;
  }

  &__arrows {
    z-index: 10;
  }
}

@include respond-up('x-large') {
  .actions-block {
    &__main {
      padding-top: 210px;
    }

    &__items {
      height: 550px;
    }
  }
}

@include respond('large') {
  .actions-block {
    &__main {
      padding-top: 170px;
    }

    &__items {
      height: 30vw;
    }
  }
}

@include respond-up('medium') {
  .actions-block {
    &__main {
      position: relative;
    }

    &__link {
      &_mobile {
        display: none;
      }
    }

    &__arrows {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

@include respond('medium') {
  .actions-block {
    &__main {
      padding-top: 98px;
    }

    &__items {
      height: 32.5vw;
    }
  }
}

@include respond-down('small') {
  .actions-block {
    &__main {
      padding-top: 76px;
    }

    &__items {
      height: 132vw;
    }

    &__link {
      &_desktop {
        display: none;
      }
    }

    &__arrows {
      padding-top: 4px;
    }
  }
}

@include respond-up('large') {
  .actions-block {
    &__main {
      transform: scale(0.77) translate3d(0, 10px, 0);
      opacity: 0;
    }
  }
}