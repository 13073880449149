.projects {
  &__menu {

  }

  &__filter {

  }

  &__main {

  }

  &__items {
    margin: 0 -15px;
  }

  &__info {

  }

  &__title {
    font-weight: 800;
    letter-spacing: 0;
    line-height: 1;
  }

  &__description {
    font-size: 17px;
    color: $gray-color;
    letter-spacing: 0;
    line-height: 1.7;
  }

  &__default-block {
    display: flex;
    flex-wrap: wrap;
  }

  &__big-block {
    display: flex;
    flex-wrap: wrap;

    .project-card {
      display: flex;
      flex-direction: column;

      &:first-child {
        flex: 0 0 100%;
        width: 66.66666%;

        .project-card {
          &__name {
            &_small {
              display: none;
            }

            &_big {
              display: block;
              flex: 1;
            }
          }

          &__perimeter {
            margin-right: 8px;
          }
        }
      }

      &:nth-child(2) {
        width: 33.33333%;
        flex: 0 0 50%;
      }

      &:last-child {
        width: 33.33333%;
        flex: 0 0 50%;
      }

      &__image {
        flex: 1;
      }
    }
  }

  &__bill-block {
    display: flex;
  }

  &__bill {
    flex: 0 0 33.33333%;
  }

  &__bill-block-wrap {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 66.66666%;

    .project-card {
      flex: 0 0 50%;
    }
  }
}

@include respond-up('x-large') {
  .projects {
    &__info {
      max-width: 970px;
      padding: 100px 0 180px;
    }

    &__filter {
      padding-bottom: 84px;
    }
  }
}

@include respond-up('large') {
  .projects {
    &__pager {
      padding-top: 32px;
    }

    &__filter {
      padding-bottom: 76px;
    }

    &__title {
      font-size: 45px;
    }

    &__description {
      padding-top: 25px;
    }

    &__empty {
      padding-bottom: 115px;
    }

    &__big-block {
      flex-direction: column;
      height: 36vw;
      max-height: 700px;


      &.projects__big-block_reverse {
        .project-card {
          display: flex;
          flex-direction: column;

          &:last-child {
            flex: 0 0 100%;
            width: 66.66666%;

            .project-card {
              &__name {
                &_small {
                  display: none;
                }

                &_big {
                  display: block;
                  flex: 1;
                }
              }

              &__perimeter {
                flex: unset;
                margin-right: 34px;
              }
            }
          }

          &:first-child {
            width: 33.33333%;
            flex: 0 0 50%;

            .project-card {
              &__name {
                &_small {
                  display: block;
                }

                &_big {
                  display: none;
                }
              }

              &__image {
                padding-top: unset;
              }

              &__perimeter {
                flex: 1;
              }
            }
          }
        }
      }

      .project-card {
        &:first-child {
          .project-card {
            &__perimeter {
              flex: unset;
            }
          }
        }

        &:nth-child(2) {
          .project-card {
            &__image {
              padding-top: unset;
            }
          }
        }

        &:last-child {
          .project-card {
            &__image {
              padding-top: unset;
            }
          }
        }
      }
    }
  }
}

@include respond('large') {
  .projects {
    &__info {
      max-width: 870px;
      padding: 80px 0 140px;
    }
  }
}

@include respond('medium') {
  .projects {
    &__info {
      padding: 60px 0 130px;
    }

    &__filter {
      padding-bottom: 54px;
    }

    &__title {
      font-size: 38px;
    }

    &__description {
      padding-top: 18px;
    }

    &__big-block {
      .project-card {
        &:first-child {
          .project-card {
            &__perimeter {
              flex: unset;
            }
          }
        }

        &__image {
          padding-top: 56%;
        }
      }
    }

    &__bill-block {
      flex-wrap: wrap;
    }

    &__bill-block-wrap {
      flex: 0 0 100%;
    }

    &__bill {
      flex: 0 0 100%;
    }

    .project-card {
      flex: 0 50%;

      &__image {
        padding-top: 56%;
      }
    }
  }
}

@include respond-down('medium') {
  .projects {
    &__pager {
      padding-top: 20px;
    }
  }
}

@include respond-down('small') {
  .projects {
    &__info {
      padding: 60px 0;
    }

    &__filter {
      padding-bottom: 36px;
    }

    &__title {
      font-size: 25px;
    }

    &__description {
      padding-top: 9px;
      font-size: 16px;
      line-height: 1.375;
    }

    &__items {
      margin: 0;
    }

    &__big-block {
      .project-card {
        flex: 0 0 100%;

        &:first-child {
          .project-card {
            flex: 0 0 100%;
            &__name {
              &_small {
                display: inline;
              }

              &_big {
                display: none;
              }
            }
          }
        }

        &:nth-child(2) {
          flex: 0 0 100%;
        }

        &:last-child {
          flex: 0 0 100%;
        }

        &__image {
          padding-top: 56%;
        }
      }
    }

    &__bill-block {
      flex-wrap: wrap;
    }

    &__bill-block-wrap {
      flex: 0 0 100%;

      .project-card {
        flex: 0 0 100%;
      }
    }

    &__bill {
      flex: 0 0 100%;
    }
  }
}