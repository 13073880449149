.wall {
  margin-bottom: 57px;
  &__title {
    font-size: 17px;
    color: rgba(51, 53, 49, 0.5);
    margin-bottom: 22px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
}

@include respond-up('x-large') {
  .wall {
    &__list {
      margin-left: -8px;
    }
  }
}

@include respond('large') {
  .wall {
    &__list {
      margin-left: -6px;
    }
  }
}

@include respond-down('medium') {
  .wall {
    &__list {
      margin-left: -8px;
    }
  }
}