.geography {
  &__main {
    position: relative;
  }

  &__leaf {
    position: absolute;
    right: 0;
  }

  &__about {

  }

  &__title {
    line-height: 1;
  }

  &__description {
    color: $gray-color;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__map {
    position: relative;
  }

  &__regions {
    position: absolute;
  }

  &__items {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: absolute;
    z-index:5;
  }

  &__placemark {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:hover {
      .geography {
        &__name {
          opacity: 1;
        }
      }
    }
  }

  &__icon {

  }

  &__shadow {
    position: absolute;
    top: 60%;
    left: -85%;
    pointer-events: none;
  }

  &__name {
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    align-items: center;
    height: 22px;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $magnolia-color;
    padding: 1px 6px 0;
    border: 1px solid #ecece3;
    pointer-events: none;
    opacity: 0;
    transition: opacity $default-transition;
    z-index: 1000;
  }

  &__russia {

  }

  &__container {
    //overflow: hidden;
  }

  &__wrap {
    position: relative;
  }

  &__numbers {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__number {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__count {
    font-weight: 800;
    color: $green-color;
    letter-spacing: 0;
    line-height: 1;
  }

  &__info {
    letter-spacing: 0;
  }
}

@include respond-up('x-large') {
  .geography {
    &__leaf {
      top: 20px;
    }

    &__description {
      font-size: 17px;
      line-height: 1.58;
    }

    &__about {
      margin-top: -50px;
      flex: 0 0 420px;
    }

    &__numbers {
      margin-top: -50px;
      width: 212px;
    }

    &__regions {
      left: 241px;
    }
  }
}

@include respond-up('large') {
  .geography {
    &__title {
      &::before {
        margin-bottom: 40px;
      }
    }

    &__wrap {
      display: flex;
      align-items: center;
    }

    &__info {
      font-size: 23px;
      line-height: 1.08;
    }

    &__count {
      font-size: 80px;
    }

    &__russia {
      width: 1720px;
      max-width: 1720px;
    }

    &__description {
      padding-top: 30px;
    }

    &__number {
      padding: 20px 0;
    }

    &__regions {
      width: 328px;
      height: 160px;
      top: 316px;
    }
  }
}

@include respond('large') {
  .geography {
    &__leaf {
      top: 40px;
    }

    &__about {
      margin-top: -80px;
      flex: 0 0 300px;
    }

    &__russia {
      margin-left: -90px;
    }

    &__numbers {
      margin-top: -80px;
      width: 175px;
    }

    &__regions {
      left: 149px;
    }
  }
}

@include respond-up('medium') {
  .geography {
    &__title {
      &::before {
        content: '';
        display: block;
        width: 64px;
        border-bottom: 4px solid $green-color;
      }
    }
  }
}

@include respond('medium') {
  .geography {
    &__leaf {
      top: 0;
      width: 102px;
    }

    &__title {
      &::before {
        margin-bottom: 30px;
      }
    }

    &__info {
      line-height: 1.25;
    }

    &__count {
      font-size: 60px;
    }

    &__description {
      padding-top: 16px;
    }

    &__about {
      max-width: 440px;
    }

    &__russia {
      width: 1530px;
      max-width: 1530px;
      margin-left: -90px;
      margin-top: -120px;
      margin-bottom: -50px;
    }

    &__numbers {
      justify-content: flex-end;
      bottom: 330px;
      left: 506px;
      width: 165px;
    }

    &__regions {
      width: 292px;
      height: 142px;
      top: 157px;
      left: 124px;
    }

    &__number {

      &:first-child {
        padding-bottom: 20px;
      }

      &:last-child {
        padding-top: 20px;
      }
    }

    &__count {
      padding-bottom: 4px;
    }
  }
}

@include respond-down('medium') {
  .geography {
    &__info {
      font-size: 20px;
    }
  }
}

@include respond-down('small') {
  .geography {
    &__leaf {
      display: none;
    }

    &__info {
      line-height: 1;
    }

    &__count {
      font-size: 50px;
    }

    &__description {
      padding-top: 10px;
    }

    &__numbers {
      position: static;
      flex-direction: row;
      justify-content: flex-start;
      padding-bottom: 56px;
    }

    &__number {
      flex: 0 0 156px;
    }

    &__count {
      padding-bottom: 6px;
    }

    &__regions {
      width: 230px;
      height: 112px;
      top: 122px;
      left: 63px;
    }

    &__russia {
      width: 1200px;
      max-width: 1200px;
      margin-left: -100px;
      margin-top: -90px;
      margin-bottom: -140px;
    }

    &__item {
      transform: scale(0.7);
    }

    &__name {
      transition: transform $default-transition;
    }
  }
}

@include respond-down('x-small') {
  .geography {
    &__russia {;
      margin-left: -140px;
    }

    &__number {
      flex: 0 0 50%;
    }

    &__name {
      &_wrap {
        white-space: normal;
        word-wrap: break-word;
      }
    }
  }
}