


.fade-enter-active, .fade-leave-active {
    transition: all .8s;
}

.fade-enter, .fade-leave-to /* .list-leave-active до версии 2.1.8 */{
    opacity: 0;
    transform: translateY(30px);
}
