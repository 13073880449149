.kit-item {
  position: relative;
  z-index: 2;
  padding-bottom: 24px;
  min-width: 200px;

  &__link {
    display: inline-block;
    font-weight: 800;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    border-bottom: 4px solid #CEAF72;
    opacity: 0;
    transition: opacity .4s ease-in-out;
  }
  &.active {
    color: #CEAF72;
  }
  &.active::before {
    opacity: 1;
  }
}

@include respond-up('x-large') {
  .kit-item {
      &__link {
        font-size: 29px;
      }
  }
}

@include respond('large') {
  .kit-item {
    &__link {
      font-size: 24px;
    }
  }
}

@include respond('medium') {
  .kit-item {
    &__link {

    }
  }
}