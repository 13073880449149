.works {
  &__image {
    display: block;
    overflow: hidden;

    &:hover {
      .works {
        &__img {
          transform: scale(1.04);
        }
      }
    }
  }

  &__img {
    display: block;
    padding-top: 64%;
    background: no-repeat center / cover;
    transition: transform $default-transition;
  }

  &__link {
    display: block;

    &:hover {
      .works {
        &__name {
          color: $green-color;
          border-bottom-color: rgba(119,177,86,0.50);
        }
      }
    }
  }

  &__name {
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.25;
    border-bottom: 1px solid $t;
    transition: border-bottom-color $default-transition, color $default-transition;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@include respond-up('x-large') {
  .works {
    &__main {
      padding-bottom: 152px;
    }

    &__items {
      margin: 0 -21px;
    }

    &__item {
      padding: 0 21px;
    }
  }
}

@include respond-up('large') {
  .works {
    &__items {
      grid-template-columns: 33.33333% 33.33333% 33.33333%;
      grid-template-areas:
              's-1 s-2 s-3'
              'b b s-4'
              'b b s-6'
              's-7 s-8 s-9';
    }

    &__item {
      margin-bottom: 42px;

      &_anime {
        animation: emergence .6s forwards;
        transform: translateY(40px);
        opacity: 0;
      }

      &:nth-child(5) {
        .works {
          &__img {
            height: 100%;
            padding-top: 0;
          }
        }
      }
    }

    &__pager {
      padding-top: 32px;
    }
  }
}

@include respond('large') {
  .works {
    &__main {
      padding-bottom: 126px;
    }

    &__items {
      margin: 0 -15px;
    }

    &__item {
      padding: 0 15px;
    }
  }
}

@include respond-up('medium') {
  .works {
    &__items {
      display: grid;
    }

    &__item {
      display: flex;
      flex-direction: column;

      &:nth-child(5) {
        grid-area: b;

        .works {
          &__image {
            height: 100%;
          }
        }
      }
    }

    &__link {
      padding-top: 14px;
    }
  }

  @for $i from 1 through 9 {
    .works {
      &__item {
        &:nth-child(#{$i}):not(:nth-child(5)) {
          grid-area: s-#{$i};
        }
      }
    }
  }
}

@include respond('medium') {
  .works {
    &__main {
      padding-bottom: 128px;
    }

    &__items {
      margin: 0 -10px;
      grid-template-columns: 50% 50%;
      grid-template-areas:
              's-1 s-2'
              's-3 s-4'
              'b b'
              's-6 s-7'
              's-8 s-9';
    }

    &__item {
      padding: 0 10px;
      margin-bottom: 38px;
    }

    &__pager {
      padding-top: 20px;
    }
  }
}

@include respond-down('small') {
  .works {
    &__main {
      padding-bottom: 62px;
    }

    &__item {
      padding-bottom: 30px;
    }

    &__link {
      padding-top: 12px;
    }

    &__name {
      font-size: 15px;
      line-height: 1.2;
    }
  }
}

@keyframes emergence {
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}