.product-offer {
  display: flex;
  justify-content: flex-end;
  position: relative;
  &__info-wrapper {

  }

  &__form {

  }

  &__hidden {
    display: none;
  }

  &__form-title {
    font-weight: bold;
    color: #333531;
    letter-spacing: 0;
  }

  &__form-description {
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
    line-height: 1.4;
  }

  &__form-button-container {
    display: flex;
    flex-direction: column;
  }

  &__form-date {
    label {
      display: none;
    }

    input[type="text"] {
      border: 2px solid #ECECE3;
      background: {
        color: #F6F6F1;
        image: url("../svg/phone-form.svg");
        repeat: no-repeat;
        position: 10px center;
      }
      padding-left: 40px;
    }

    .form-field {
      margin-bottom: 0;
    }

    .form-field.name {
      display: none;
    }
  }

  &__policy {
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
    line-height: 1.4;
  }

  &__link {
    color: $green-color;
    border-bottom: 1px solid transparent;
    transition: $default-transition;
    &:hover {
      border-bottom: 1px solid $green-color;
      transition: $default-transition;
    }
  }

  &__form-success {

  }

  &__images-container {
    position: absolute;
    width: 100%;
  }

  &__images {
    height: 100%;
  }

  &__image-container {

  }

  &__image {
    max-height: 100%;
    height: 100%;
    width: 100%;
  }

  &__arrows {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
    border-right: 1px solid $light-gray-color;
    border-bottom: 1px solid $light-gray-color;
  }
}

@include respond-up('x-large') {
  .product-offer {
    &__info-wrapper {
      flex: 0 0 57%;
      border: 25px solid #F6F6F1;
      padding: 57px 73px 62px 8vw;
    }
  }
}

@include respond('large') {
  .product-offer {
    &__info-wrapper {
      flex: 0 0 57%;
      border: 25px solid #F6F6F1;
      padding: 57px 3vw 62px 17vw;
    }
  }
}

@include respond-up('large') {
  .product-offer {

    &__form-title {
      font-size: 2vw;
      margin-bottom: 24px;
    }

    &__form-description {
      font-size: 16px;
      margin-bottom: 31px;
    }

    &__form-button-container {
      margin-bottom: 17px;
    }

    &__form-date {
      input[type="text"] {
        height: 53px;
      }
    }

    &__form-button {
      //flex: 1 1 100%;
      height: 53px;
      padding: 0;
    }

    &__policy {
      font-size: 13px;
    }

    &__link {

    }

    &__form-button-container {

    }

    &__form-date {

    }

    &__form-button {

    }

    &__policy {

    }

    &__link {

    }

    &__form-success {
      &.recall {
        &__success {
          flex-direction: column;
        }
      }

      .recall {
        &__success-image {
          margin-right: 0;
          margin-bottom: 32px;
        }

        &__success-title {
          font-size: 34px;
        }
      }
    }

    &__images-container {
      max-width: 687px;
      left: 0;
      top: 0;
      bottom: 25px;
      height: calc(100% - 25px);
    }

    &__images {

    }

    &__image {

    }

    &__arrows {

    }

    &__arrows-small-container {
      display: none;
    }

    &__info-small {
      display: none;
    }
  }
}

@include respond('medium') {
  .product-offer {
    max-height: 431px;
    height: 431px;
    &__info-wrapper {
      display: none;
    }

    &__form {

    }

    &__form-title {
      font-size: 2vw;
      margin-bottom: 24px;
    }

    &__form-description {
      font-size: 16px;
      margin-bottom: 31px;
    }

    &__form-button-container {
      margin-bottom: 17px;
    }

    &__form-date {
      input[type="text"] {
        height: 53px;
      }
    }

    &__form-button {
      flex: 1 1 100%;
      height: 53px;
      padding: 0;
    }

    &__policy {
      font-size: 13px;
    }

    &__link {

    }

    &__form-button-container {

    }

    &__form-date {

    }

    &__form-button {

    }

    &__policy {

    }

    &__link {

    }

    &__form-success {

    }

    &__images-container {
      height: 100%;
    }

    &__images {

    }

    &__image {
      object-fit: cover;
    }

    &__arrows {

    }

    &__arrows-small-container {
      display: none;
    }

    &__info-small {
      z-index: 100;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 2px solid $magnolia-color;
      border-bottom: 2px solid $magnolia-color;
      margin: 45px -44px 56px;
      padding: 13px 44px;
      position: sticky;
      bottom: 0;
    }

    &__info-small-title {
      font-weight: 800;
      white-space: nowrap;
      font-size: 26px;
      color: $black-color;
      letter-spacing: 0;
    }

    &__info-small-description {
      font-size: 13px;
      color: $gray-color;
      line-height: 1.5;
      margin: 0 4px 0 19px;
    }

    &__info-small-button {
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 53px;
      max-width: 193px;
      width: 193px;
      font-size: 13px;
      font-weight: 800;
    }
  }
}

@include respond-down('small') {
  .product-offer {
    /*max-height: 278px;
    height: 278px;*/

    &__info-wrapper {
      display: none;
    }

    &__form {

    }

    &__form-title {
      font-size: 2vw;
      margin-bottom: 24px;
    }

    &__form-description {
      font-size: 16px;
      margin-bottom: 31px;
    }

    &__form-button-container {
      margin-bottom: 17px;
    }

    &__form-date {
      input[type="text"] {
        height: 53px;
      }
    }

    &__form-button {
      flex: 1 1 100%;
      height: 53px;
      padding: 0;
    }

    &__policy {
      font-size: 13px;
    }

    &__link {

    }

    &__form-button-container {

    }

    &__form-date {

    }

    &__form-button {

    }

    &__policy {

    }

    &__link {

    }

    &__form-success {

    }

    &__images-container {
      height: 100%;
      position: relative;
    }

    &__images {
      height: 44vw;
      max-height: 44vw;
      //padding-bottom: 63%;
    }

    &__image {
      object-fit: cover;
    }

    &__arrows {
      position: relative;
      border: none;
      margin: 18px 0 23px;
    }

    &__arrows-small-container {
      display: none;
    }

    &__info-small {
      z-index: 100;
      background-color: white;
      position: sticky;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 2px solid $magnolia-color;
      border-bottom: 2px solid $magnolia-color;
      margin: 0 -22px 56px;
      padding: 13px 22px;
    }

    &__info-small-title {
      font-weight: 800;
      text-align: center;
      font-size: 17px;
      color: $black-color;
      line-height: 1;
      padding-right: 40px;
      letter-spacing: 0;
    }

    &__info-small-description {
      display: none;
    }

    &__info-small-button {
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding: 0 13px;
      height: 40px;
      max-width: 193px;
      width: 183px;
      font-size: 13px;
      font-weight: 800;
    }
  }
}