.project {
  &__slider {}

  &__gallery {
    display: flex;
    align-items: flex-start;
    & .tns-outer {
      width: 100%;
      & .tns-controls {
        display: none;
      }
      & .tns-nav {
        display: none;
      }
    }
  }


  &__thumbs {
    width: 10%;
    margin-right: 36px;
  }

  &__thumbs-item {
    position: relative;
    background-size: cover;
    background-position: center;
    border: 2px solid #F6F6F1;
    &:not(:nth-last-child(1)) {
      margin-bottom: 21px;
    }
    &:before {
      content: '';
      width: 5px;
      height: 100%;
      position: absolute;
      left: -16px;
      background-color: #CEAF72;
      opacity: 0;
      transition: opacity .5s ease-in-out;
    }
    &.tns-nav-active:before {
      opacity: 1;
    }
  }

  &__slider-wrapper {
    position: relative;
    width: 90%;
  }

  &__slider-item {
    background-size: cover;
    background-position: center;
  }

  &__hidden {
    display: none;
  }

  &__contain {
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__arrows {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__info {
    //display: flex;
    position: relative;
  }

  &__specifications-item {
    display: flex;
    margin-bottom: 24px;
  }

  &__specifications-item-label {
    display: block;
    color: $gray-color;

  }

  &__specifications-item-value {
    white-space: nowrap;
    font-weight: 500;
    font-size: 17px;
  }

  &__description {
    color: $gray-color;
  }

  &__offer {
    background-color: #FFFFFF;
  }

  &__offer-form {
    margin-bottom: 48px;
    label {
      display: none;
    }
    & .form-field {
      margin: 0;
      input {
        height: 50px;
        border: 2px solid $magnolia-color;
        background: {
          color: #F6F6F1;
          repeat: no-repeat;
          position: 10px center;
        }
        padding-left: 50px;
        &::placeholder {
          font-family: 'Geometria';
        }
      }
    }
  }
  &__price {
    display: block;
    font-size: 45px;
    font-weight: 800;
    margin-bottom: 37px;
  }

  &__offer-policy {
    max-width: 294px;
    font-size: 13px;
    color: $gray-color;
    line-height: 1.38em;
  }

  &__offer-policy-link {
    font-size: 13px;
    color: $green-color;
    line-height: 1.38em;
  }

  &__offer-button {
    margin-bottom: 17px;
    white-space: nowrap;
    display: block;
    width: 100%;
    padding: 20px 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 800;
    letter-spacing: 0.15em;
  }

  &__order {
    display: block;
    width: 100%;
    height: 85px;
    background-color: $magnolia-color;
    background-image: url('../images/base/offer-bg.png');
    background-repeat: no-repeat;
    background-position: 120% 50%;
  }

  &__order-label {
    display: block;
    color: $primary-color;
    font-weight: 700;
    padding-top: 23px;
    padding-left: 20px;
    line-height: 1em;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 170px;
  }

  &__nav-prev {
    width: 380px;
    height: 54px;
    background-color: #F6F6F1;
    display: flex;
    align-items: center;
  }

  &__nav-prev-image {
    display: block;
    width: 96px;
    height: 100%;
  }

  &__nav-prev-label {
    color: $green-color;
    padding: 20px 34px;
    font-size: 15px;
  }

  &__nav-next {
    width: 380px;
    height: 54px;
    background-color: #F6F6F1;
    display: flex;
    align-items: center;
  }

  &__nav-next-image {
    display: block;
    width: 96px;
    height: 100%;
    background: {
      position: center;
      size: cover;
    };
  }

  &__nav-next-label {
    color: $green-color;
    padding: 20px 40px 20px 50px;
    font-size: 15px;
  }

  &__nav-back {
    display: inline-block;
    padding: 18px 35px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.15em;
    font-size: 13px;
    transition: $default-transition;
  }

  &__nav-back-tablet {
    display: none;
  }

  &__turn {
    position: relative;

  }

  &__turn-number {
    color: $primary-color;
    display: block;
  }

  &__turn-term {
    color: $green-color;
    display: block;
    margin-bottom: 11px;
  }

  &__turn-description {
    color: $gray-color;
  }

  &__turn-title {
    font-size: 65px;
    font-weight: 800;
    margin-bottom: 44px;
  }

  &__turn-list {

  }

  &__turn-item {
    padding-top: 37px;
    padding-right: 115px;
  }

  &__turn-number {
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 11px;
  }

  &__turn-name {
    display: block;
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 19px;
  }

  &__turn-term {

  }

  &__turn-arrows {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__turn-description {
    line-height: 1.375em;
  }

  &__similar-title-block {
    position: relative;
  }

  &__similar {
    position: relative;
    margin-bottom: 190px;
  }

  &__similar-title {
    font-size: 65px;
    font-weight: 800;
    margin-bottom: 67px;
  }

  &__similar-image {
    position: relative;
    display: block;
    width: 100%;
    height: 260px;
    background: {
      position: center;
      size: cover;
    }
  }

  &__similar-label {
    color: #FFFFFF;
    font-weight: 800;
  }

  &__similar-arrows {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__similar-info {
    height: 57px;
    display: flex;
    align-items: center;
    padding: 0 21px;
    justify-content: space-between;
  }

  &__similar-icon {
    margin-right: 7px;
  }

  &__similar-value {
    margin-right: 15px;
    position: relative;
    top: 2px;
    font-size: 14px;
    font-weight: 500;
  }

  &__similar-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    background: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.75) 100%);
  }

  &__similar-rub {
    position: relative;
    top: -2px;
  }

  &__similar-info-price {
    font-size: 18px;
    font-weight: bold;
  }
}

.tns-controls {
  display: none;
}

.kit-info-item:nth-child(odd) {
  background-color: #FFFFFF;
}

.kit-info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F6F6F1;
  padding: 10px 25px;
  &__name {
    font-size: 15px;
  }
  &__icon {
    position: relative;
    top: 2px;
  }
  &.odd {
    background-color: #FFFFFF;
  }



}

@include respond-up('x-large') {
  .project {
    &__slider-wrapper {
      order: 1;
    }

    &__similar-name {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &__similar-label {
      display: block;
      padding: 10px;
      font-size: 20px;
    }


    &__left {
      min-width: 969px;
    }

    &__thumbs {
      order: 0;
    }

    &__slider-item {
      height: 727px;
    }

    &__gallery {
      margin-bottom: 112px;
    }

    &__calculator {
      display: flex;
    }

    &__right {
      padding-left: 127px;
    }

    &__offer {
      position: sticky;
      top: 100px;
      margin-bottom: 148px;
      width: 380px;
    }

    &__description {

    }

    &__thumbs-item {
      padding-bottom: calc((85/151)*100%);
    }

    &__options {
      margin-bottom: 127px;
    }

    &__order-success-icon {
      margin-bottom: 30px;
    }

    &__order-success-status {
      font-size: 43px;
      font-weight: 800;
      margin-bottom: 24px;
    }

    &__order-success-description {
      color: $gray-color;
      font-size: 16px;
    }

    &__info {
      display: flex;
    }

    &__specifications-item-label {
      min-width: 205px;
    }



    &__specifications {
      margin-right: 137px;
    }

    &__turn {
      margin-bottom: 170px;
    }

    &__similar {
      .tns-ovh {

      }

      .tns-inner {
        max-width: 1468px;
        margin: 0 auto !important;
      }
    }

  }
}

@include respond-up('large') {
  .project {
    &__offer-mobile {
      display: none;
    }
    &__info {
      margin-bottom: 118px;
    }
    &__order-success-icon {
      margin-bottom: 30px;
    }

    &__order-success-status {
      font-size: 35px;
      font-weight: 800;
      margin-bottom: 24px;
    }

    &__order-success-description {
      color: $gray-color;
      font-size: 16px;
    }

    //&__title {
    //  transform: translate3d(0, 67px, 0);
    //  opacity: 0;
    //}
  }
}

@include respond('large') {
  .project {
    &__slider {
      height: 727px;
    }

    &__slider-wrapper {
      height: 37.8vw;
      order: 1;
    }

    &__thumbs {
      order: 0;
    }

    &__thumbs-item {
      padding-bottom: calc((85/151)*100%);
    }

    &__slider-item {
      height: 37.8vw;
    }

    &__gallery {
      margin-bottom: 48px;
    }

    &__description {

    }

    &__options {
      max-width: 969px;
    }

    &__calculator {
      display: flex;
      justify-content: space-between;
      margin-bottom: 97px;
    }

    &__left {
      max-width: 1030px;
      padding-right: 40px;
    }

    &__right {

    }

    &__offer {
      width: 308px;
      position: sticky;
      top: 100px;
      margin-bottom: 80px;
    }

    &__info {
      display: flex;
    }

    &__specifications-item-label {
      min-width: 205px;
    }

    &__specifications {
      margin-right: 97px;
    }

    &__turn {
      margin-bottom: 133px;
      .tns-ovh {
        margin-right: -150px;
        margin-left: -150px;
      }
      .tns-inner {
        margin-left: 150px !important;
      }
    }

    &__similar {
      .tns-ovh {

      }
      .tns-inner {
        margin-left: 150px !important;
      }
    }

    &__similar-name {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &__similar-label {
      display: block;
      padding: 10px;
      font-size: 20px;
    }

    &__similar-label {

    }
  }

  .kit-info-item {
    &__name {
      font-size: 15px;
    }

    &__link {
      font-size: 24px;
    }
  }

  .kit-item {
    &__link {
      font-size: 24px;
    }
  }
}

@include respond('medium'){
  .project {
    &__title {

    }

    &__gallery {
      display: block;
      margin-bottom: 55px;
    }

    &__thumbs {
      width: 100%;
      display: flex;
      margin-bottom: 71px;
    }

    &__thumbs-item {
      width: 11.19vw;
      height: 6.11vw;
      margin-right: 10px;
      &:before {
        width: 11.19vw;
        height: 4px;
        position: absolute;
        bottom: -16px;
        left: 0;
      }
    }

    &__slider-wrapper {
      height: 49.8vw;
      width: 100%;
      margin-bottom: 13px;
    }

    &__slider-item {
      height: 49.8vw;
    }

    &__specifications {
      margin-right: 97px;
      padding: 0;
    }

    &__specifications-item-label {
      display: block;
      min-width: 205px;
    }

    &__info {
      display: flex;
      margin-bottom: 26px;
    }

    &__description {
      width: 50%;
      padding-right: 0;
    }

    &__offer-mobile {
      height: 80px;
      margin-bottom: 71px;
      background-color: #FFFFFF;
      padding: 0 44px;
    }

    &__offer-mobile-price {
      font-size: 26px;
      width: 170px;
    }

    &__offer-mobile-order {
      background-color: #F5F5F0;
      background: {
        image: url("../images/base/offer-bg.png");
        size: 60%;
        repeat: no-repeat;
        position: 120% 50%;
      };
    }

    &__offer-mobile-order-label {
      display: block;
      font-size: 16px;
      font-weight: bold;
      color: $primary-color;
      line-height: 1em;
      padding: 10px 55px 10px 15px;
    }

    &__offer-mobile-button {
      padding: 17px 20px;
      transition: background-color .3s ease-in-out;
      &:hover {
        background-color: darken($green-color, 3);
      }
    }

    &__project-price {
      font-size: 18px;
    }

    &__nav-back-tablet {
      display: inline-block;
      padding: 18px 35px;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      font-weight: 800;
      font-size: 13px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    &__nav {
      margin-bottom: 55px;
    }

    &__turn {
      margin-bottom: 133px;
      .tns-ovh {
        margin-left: -44px;
        margin-right: -44px;
      }
      .tns-inner {
        margin-left: 44px !important;
      }
    }

    &__turn-title {
      font-size: 48px;
      margin-bottom: 17px;
    }

    &__similar {
      .tns-ovh {

      }
      .tns-inner {
        margin-left: 44px !important;
      }
    }

    &__similar-title {
      font-size: 48px;
      margin-bottom: 43px;
      padding-left: 10px;
    }

    &__similar-info {
      flex-wrap: wrap;
    }

    &__similar-info-data {
      display: block;
      padding-top: 15px;
      margin-bottom: 15px;
    }

    &__similar-item {
      height: 250px;
    }

    &__options {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &__calc-wrapper {
      position: relative;
    }

    &__offer-mobile-block {
      position: sticky;
      bottom: 0;
      z-index: 1000;
      margin-left: -44px;
      margin-right: -44px;
    }
  }
}

@include respond-down('medium') {
  .project {
    &__offer {
      display: none;
    }

    &__info {

    }

    &__specifications-item-label {
      width: 205px;
    }

    &__offer-mobile {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items:center;
      border-top: 2px solid $magnolia-color;
      border-bottom: 2px solid $magnolia-color;
    }

    &__offer-mobile-price {
      display: block;
      font-weight: 800;
      //width: 120px;
    }

    &__offer-mobile-button {
      display: inline-block;
      font-size: 13px;
      color: #FFFFFF;
      background-color: $green-color;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 0.15em;
    }

    &__nav-block {
      margin-bottom: 114px;
    }

    &__nav-prev {
      width: 330px;
      height: 54px;
    }

    &__nav-next {
      width: 330px;
      height: 54px;
    }

    &__nav-prev-image {
      width: 96px;
      height: 100%;
    }

    &__nav-prev-label {
      padding: 7px;
    }

    &__nav-back {
      display: none;
    }

    &__nav-next-label {
      padding: 20px;
    }

    &__similar-image {
      height: 168px;
    }

    &__similar-name {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &__similar-label {
      display: block;
      padding: 10px;
      font-size: 16px;
    }

    &__left {
      position: relative;
    }
  }
}

@include respond-up ('medium') {
  .project {
    &__nav-mobile {
      display: none;
    }
    &__nav-prev-image {
      background: {
        size: cover;
        position: center;
      };
    }
  }

  .offer-mobile-order {
    display: none;
  }
}





@include respond-down ('small') {
  .project {
    &__offer-mobile {
      height: 55px;
      margin-bottom: 48px;
      background-color: #FFFFFF;
      padding: 0 22px;
    }

    &__offer-mobile-block {
      position: sticky;
      bottom: 0;
      z-index: 1000;
      margin-left: -22px;
      margin-right: -22px;
    }

    &__calc-wrapper {
      position: relative;
    }

    &__options {
      padding-bottom: 40px;
    }

    &__gallery {
      display: block;
      margin-bottom: 81px;
    }

    &__slider-wrapper {
      width: 100%;
    }

    &__thumbs {
      display: none;
    }

    &__slider-item {
      height: 49.6vw;
    }

    &__calculator {
      margin-bottom: 51px;
    }

    &__offer-mobile-order {
      display: none;
    }

    &__offer-mobile-button {
      padding: 12px 17px;
    }

    &__offer-mobile-price {
      font-size: 18px;
    }

    &__info {
      margin-bottom: 33px;
    }

    &__arrows {
      left: 50%;
      bottom: -60px;
      transform: translateX(-50%);
    }

    &__nav-block {
      display: none;
    }

    &__nav-mobile {
      margin-bottom: 70px;
    }

    &__nav-mobile-inner {
      display: flex;
      justify-content: space-between;
      color: $green-color;
    }

    &__turn {
      margin-bottom: 70px;
      .tns-ovh {
        margin-right: -22px;
        margin-left: -22px;
      }
      .tns-inner {
        margin-left: 22px !important;
      }
    }

    &__turn-title {
      font-size: 30px;
      margin-bottom: 0px;
    }

    &__turn-arrows {
      display: none !important;
    }

    &__similar-info {
      display: block;
    }

    &__similar {
      margin-bottom: 63px;
      .tns-inner {
        margin-left: 22px !important;
      }
    }

    &__similar-item {
      height: 250px;
    }

    &__similar-info-data {
      display: block;
      padding-top: 15px;
      margin-bottom: 15px;
    }

    &__similar-info-price {
      position: relative;
      z-index: 1000;
    }

    &__similar-title {
      font-size: 30px;
      margin-bottom: 19px;
    }

    &__similar-arrows {
      display: none !important;
    }
  }
  .offer-mobile-order {
    display: block;
    height: 53px;
    background-color: #F5F5F0;
    padding: 5px 15px;
    color: $primary-color;
    font-weight: bold;
    margin-top: 34px;
    background: {
      image: url("../images/base/offer-bg.png");
      position: right;
      size: 150px 58px;
      repeat: no-repeat;
    }
  }
}

@include respond-down('x-small') {
  .project {
    &__specifications-item-label {
      width: 175px;
    }
  }
}