.work {
  &__title {
    font-weight: 800;
    letter-spacing: 0;
    line-height: 1;
  }

  &__slider {
    .tns-controls {
      display: none;
    }
  }

  &__image {
    padding-top: 64%;
    background: no-repeat center / cover;

    &_small {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        background-color: $t;
        transition: background-color $default-transition;
      }
    }
  }

  &__form {
    .form-field.project {
      display: none;
    }
  }
}

@include respond-up('large') {
  .work {
    &__main {
      padding-bottom: 90px;
    }

    &__thumbs {
      margin: 12px -12px 0;
    }

    &__thumb {
      margin-top: 36px;
      padding: 0 12px;

      &.tns-nav-active {
        .work {
          &__image {
            &_small {
              &::after {
                bottom: -16px;
                height: 4px;
              }
            }
          }
        }
      }
    }
  }

  @for $i from 1 through 7 {
    .work {
      &__thumb {
        &:nth-child(#{$i}) {
          margin-top: 0;
        }
      }
    }
  }
  .index-works__title {
    transform: translate3d(0, 30px, 0);
    opacity: 0;
  }
}

@include respond-up('medium') {
  .work {
    &__title {
      font-size: 35px;
      padding-bottom: 30px;
    }

    &__slider {
      position: relative;
    }

    &__arrows-wrap {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__thumbs {
      display: flex;
      flex-wrap: wrap;
    }

    &__thumb {
      flex: 0 0 14.28571%;
      cursor: pointer;

      &.tns-nav-active {
        .work {
          &__image {
            &_small {
              &::after {
                background-color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .work {
    &__main {
      padding-bottom: 58px;
    }

    &__thumbs {
      margin: 8px -8px 0;
    }

    &__thumb {
      padding: 0 8px;

      &.tns-nav-active {
        .work {
          &__image {
            &_small {
              &::after {
                bottom: -11px;
                height: 3px;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .work {
    &__main {
      padding-bottom: 32px;
    }

    &__title {
      font-size: 25px;
      padding-bottom: 20px;
    }

    &__arrows {
      padding-top: 4px;
    }

    &__thumbs {
      display: none;
    }
  }
}