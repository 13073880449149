.pellet-quality {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__certificate-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
  }

  &__certificate {
    background: {
      size: contain;
      repeat: no-repeat;
      color: white;
    };
    box-shadow: 0 20px 150px 0 rgba(221,225,219,0.89);
    &_1 {
      align-self: flex-end;
    }

    &_2 {
      align-self: flex-start;
    }
  }

  &__info {
    flex: 0 0 35.5%;

    &:before {
      content: '';
      display: block;
      width: 64px;
      height: 4px;
      background-color: $green-color;
    }
  }

  &__info-title {
    font-weight: 800;
    color: $black-color;
    letter-spacing: 0;
    line-height: 1;
  }

  &__info-text {
    color: $gray-color;
    letter-spacing: 0;
    line-height: 1.6;
  }
}

@include respond-up('x-large') {
  .pellet-quality {
    display: flex;
    justify-content: space-between;
    &__certificate-border {
      flex: 0 0 57%;
      min-height: 568px;
      border: 25px solid $magnolia-color;
      position: relative;
      left: -280px;
    }

    &__certificate-container {
      right: -210px;
      top: -74px;
      bottom: -72px;
      left: 243px;
    }

    &__certificate {
      height: 476px;
      width: 367px;
    }

    &__info {
      flex: 0 0 35.5%;

      &:before {
        margin-bottom: 50px;
      }
    }

    &__info-title {
      font-size: 55px;
    }

    &__info-text {
      font-size: 17px;
    }
  }
}

@include respond('large') {
  .pellet-quality {
    display: flex;
    justify-content: space-between;
    &__certificate-border {
      flex: 0 0 57%;
      min-height: 462px;
      border: 25px solid $magnolia-color;
      position: relative;
      left: -190px;
    }

    &__certificate-container {
      position: absolute;
      right: -175px;
      top: -63px;
      bottom: -63px;
      left: 198px;
      display: flex;
      justify-content: space-between;
    }

    &__certificate {
      height: 387px;
      width: 299px;
      background: {
        size: contain;
        repeat: no-repeat;
      };
      &_1 {
        align-self: flex-end;
        margin-right: 35px;
      }

      &_2 {
        align-self: flex-start;
      }
    }

    &__info {
      flex: 0 0 35.5%;

      &:before {
        margin-bottom: 47px;
      }
    }

    &__info-title {
      font-size: 45px;
      margin-bottom: 37px;
    }

    &__info-text {
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .pellet-quality {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    &__certificate-border {
      flex: 1 1 100%;
      min-height: 439px;
      width: 100%;
      border: 21px solid $magnolia-color;
      position: relative;
      left: -190px;
    }

    &__certificate-container {
      position: absolute;
      right: -175px;
      top: -60px;
      bottom: -60px;
      left: 198px;
      display: flex;
      justify-content: space-between;
    }

    &__certificate {
      height: 409px;
      width: 316px;
      background: {
        size: contain;
        repeat: no-repeat;
      };
      &_1 {
        align-self: flex-end;
        margin-right: 35px;
      }

      &_2 {
        align-self: flex-start;
      }
    }

    &__info {
      flex: 1 1 100%;
      margin-right: 170px;
      margin-bottom: 115px;

      &:before {
        margin-bottom: 34px;
      }
    }

    &__info-title {
      font-size: 38px;
      margin-bottom: 16px;
    }

    &__info-text {
      font-size: 16px;
      margin-right: 73px;
    }
  }
}

@include respond-down('small') {
  .pellet-quality {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    &__certificate-border {
      flex: 1 1 100%;
      min-height: 200px;
      width: 100%;
      border: 15px solid $magnolia-color;
      position: relative;
      left: -38px;
    }

    &__certificate-container {
      position: absolute;
      right: -49px;
      top: -45px;
      bottom: -45px;
      left: 35px;
      display: flex;
      justify-content: space-between;
    }

    &__certificate {
      height: 204px;
      width: 155px;
      background: {
        size: contain;
        repeat: no-repeat;
      };
      &_1 {
        align-self: flex-end;
        margin-right: 15px;
      }

      &_2 {
        align-self: flex-start;
      }
    }

    &__info {
      flex: 1 1 100%;
      margin-bottom: 69px;

      &:before {
        display: none;
      }
    }

    &__info-title {
      font-size: 25px;
      margin-bottom: 9px;
    }

    &__info-text {
      font-size: 16px;
    }
  }
}