.foundation-item {
  position: relative;
  margin-bottom: 20px;
  border: 2px solid transparent;
  transition: border .4s ease-in-out;

  &:hover {
    border: 2px solid #ECECE3;
  }

  &__link {
    display: block;
    background-color: #F6F6F1;
    padding: 18px;
  }
  &.active {
    border: 2px solid #CEAF72;
    color: #CEAF72;
  }
  &__icon {
    position: absolute;
    top: -7px;
    right: -7px;
    opacity: 0;
    transition: opacity .4s ease-in-out;
  }
  &.active &__icon {
    opacity: 1;
  }
  &__image {
    position: absolute;
    width: 119px;
    height: 61px;
    top: 10px;
    right: 0;

  }
  &__label {
    display: block;
    width: 100px;
  }
}

@include respond-up('x-large') {
  .foundation-item {
    margin-right: 10px;
    margin-left: 10px;

    &__link {
      width: 302px;
      height: 82px;
    }
  }
}

@include respond('large') {
  .foundation-item {
    margin-right: 8px;
    margin-left: 8px;

    &__link {
      width: 300px;
      height: 82px;
    }
  }
}

@include respond('medium') {
  .foundation-item {
    margin-right: 8px;
    margin-left: 8px;

    &__link {
      width: 328px;
      height: 82px;
    }
  }
}