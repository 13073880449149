.filter {
  &__form {
    display: flex;
  }

  &__field {
    display: flex;
    align-items: center;
  }

  &__label {
    label {
      padding-right: 16px;
      font-size: 15px;
      color: $gray-color;
      letter-spacing: 0;
    }
  }

  &__input {

  }

  &__floors {
    input[type='checkbox'] {
      display: none;
    }

    ul {
      display: flex;
    }

    input:checked + label {
      background: $green-color;
      border: 2px solid $green-color;
      color: #fff;
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $magnolia-color;
      border: 2px solid #ECECE3;
      font-size: 16px;
      color: $black-color;
      font-weight: 500;
      letter-spacing: 0;
      padding-top: 2px;
      cursor: pointer;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: background-color $default-transition, color $default-transition, border $default-transition;
    }
  }

  &__area {
    input[type='text'] {

    }
  }

  &__button {

  }

  &__reload {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0;
    transition: color $default-transition;

    &:hover {
      color: $green-color;

      svg {
        filter: contrast(100%) brightness(100%);
      }
    }

    svg {
      margin-top: -4px;
      margin-right: 10px;
      filter: contrast(0) brightness(60%);
      transition: filter $default-transition;
    }
  }
}

@include respond-up('x-large') {
  .filter {
    &__form {
      padding-top: 55px;
    }

    &__field {
      padding-right: 70px;

      &.filter__order {
        padding-right: 0;
        flex: 1;
      }
    }
  }
}


@include respond-up('large') {
  .filter {
    &__form {
      justify-content: space-between;
    }

    &__button {
      display: none;
    }

    &__floors {
      label {
        height: 53px;
        width: 58px;
      }

      li {
        padding-right: 9px;
      }
    }
  }
}

@include respond('large') {
  .filter {
    &__field {
      padding-right: 12px;
    }

    &__field-area {
      flex: 1;
    }

    &__area {
      max-width: 280px;
    }
  }
}

@include respond-up('medium') {
  .filter {
    &__button {
      display: none;
    }

    &__reload {
      &_mobile {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .filter {
    &__form {
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1000px;
      margin: 0 auto;
    }

    &__field-floors {
      flex: 0 0 250/680*100%;
      margin-bottom: 50px;
    }

    &__field-area {
      margin-bottom: 50px;
    }
  }
}

@include respond-down('medium') {
  .filter {
    &__floors {
      label {
        height: 48px;
        width: 52px;
      }

      li {
        padding-right: 13px;
      }
    }
  }
}

@include respond-down('small') {
  .filter {
    border-top: 2px solid #F6F6F1;
    border-bottom: 2px solid #F6F6F1;

    &__form {
      padding: 28px 0;
      flex-wrap: wrap;
    }

    &__field {
      flex: 0 0 100%;
      margin-bottom: 30px;
    }

    &__button {
      padding: 12px 28px;
      margin-right: 48px;
    }

    &__reload {
      &_desktop {
        display: none;
      }
    }
  }
}