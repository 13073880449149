.roof-item {
  position: relative;
  background-color: #F6F6F1 ;
  border: 2px solid transparent;
  &__link {
    display: block;
    padding: 18px;
  }
  &__icon {
    position: absolute;
    top: -7px;
    right: -7px;
    opacity: 0;
  }
  &.active {
    border: 2px solid #CEAF72;
    color: $primary-color;
  }
  &.active &__icon {
    opacity: 1;
  }
  &__image {
    position: absolute;
    width: 119px;
    height: 61px;
    top: 10px;
    right: 0;
    background: {
      size: cover;
      position: right;
    };
  }
}

@include respond-up('x-large') {
  .roof-item {
    margin: 0 10px 20px 10px;
    &__link {
      width: 302px;
      height: 82px;
    }
    &__label {
      display: block;
      max-width: 200px;
    }
  }
}

@include respond('large') {
  .roof-item {
    margin: 0 8px 20px 8px;
    &__link {
      width: 300px;
      height: 82px;
    }
  }
}

@include respond('medium') {
  .roof-item {
    margin: 0 8px 20px 8px;
    &__link {
      width: 328px;
      height: 82px;
    }
  }
}

@include respond-down('small') {
  .roof-item {
    margin: 0 0px 14px 0px;
    &__link {

    }

    &__image {
      width: 100px;
      height: 40px;
      top: 10px;
    }
  }
}