
.options {
    &__question {
        display: flex;
        align-items: center;
    }
    &__question-icon {
        margin-right: 12px;
    }
    &__question-label {
        position: relative;
        top: -1px;
        font-size: 17px;
        color: #77B156;
        border-bottom: 1px dashed #77B156;
        transition: color .4s ease-in-out, border .4s ease-in-out;

        &:hover {
            color: #5E963E;
            border-bottom: 1px dashed #5E963E;
        }
    }
}
