.index-page {
  &__main {
    overflow: hidden;
  }

  &__about {

  }

  &__geography {

  }

  .production__item_advantage {
    display: none;
  }
}

@include respond-up('x-large') {
  .index-page {
    &__geography {
      margin-top: -120px;
    }

    &__production {
      padding-top: 168px;
    }

    &__production-list {
      padding-top: 75px;
    }
  }
}

@include respond('large') {
  .index-page {
    &__geography {
      margin-top: -50px;
    }

    &__production {
      padding-top: 160px;
    }

    &__production-list {
      padding-top: 60px;
    }
  }
}

@include respond('medium') {
  .index-page {
    &__geography {
      margin-top: 65px;
    }

    &__production {
      padding-top: 120px;
    }

    &__production-list {
      padding-top: 40px;
    }
  }
}

@include respond-down('small') {
  .index-page {
    &__geography {
      margin-top: 60px;
    }

    &__production {
      padding-top: 60px;
    }

    &__production-list {
      padding-top: 25px;
    }
  }
}

@include respond-up('large') {
  .index-page {
    &__title {
      transform: translate3d(0, 67px, 0);
      opacity: 0;
    }

    &__production-title {
      opacity: 0;
      transform: translate3d(0, 30px, 0);
    }

    .production-item {
      opacity: 0;
      transform: scale(0.4);
    }

    .about-block__item {
      transform: translate3d(0, 40px, 0);
      opacity: 0;
    }

    .about-block__title {
      transform: translate3d(0, 30px, 0);
      opacity: 0;
    }

    .about-video {
      transform: translate3d(240px, 0, 0);
      opacity: 0;
    }

    .geography__russia {
      opacity: 0;
      transform: translate3d(245px, 0, 0);
    }

    .geography__items {
      z-index: 1000;
    }

    .geography__item {
      opacity: 0;
      transform: translate3d(0, -90px, 0);
    }

    .geography__number {
      opacity: 0;
      transform: translate3d(0, 40px, 0);
    }

    .geography__about {
      opacity: 0;
      transform: translate3d(-151px, 0, 0);
    }

    .advantages__item {
      transform: translate3d(0, 100px, 0);
      opacity: 0;
    }

    .recall__man {
      opacity: 0;
      transform: translate3d(-75px, 0 , 0);
    }
  }
}