.menu {
  background-color: #FFFFFF;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.7s ease-in-out;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  &__nav-list {

  }

  &__nav-item {

  }

  &__nav-link {

  }

  &__nav-link-label {

  }
}

@include respond-up('large') {
  .menu {
    display: none;
    &__nav-list {

    }

    &__nav-item {

    }

    &__nav-link {

    }

    &__nav-link-label {

    }
  }
}

@include respond('medium') {
  .menu {
    min-height: calc(100vh - 80px);
    &__nav-link-label {
      transition: transform $default-transition;
      display: block;
    }
    &__nav-link {
      display: block;
      padding: 14px 44px;
      border-bottom: 1px solid #D8D8D8;
      font-size: 19px;
      font-weight: 500;
      &:hover .menu__nav-link-label {
        transform: translate3d(10px, 0, 0);
      }
    }
  }
}

@include respond-down('small') {
  .menu {
    min-height: calc(100vh - 56px);
    &__nav-link-label {
      transition: transform $default-transition;
      display: block;
    }
    &__nav-link {
      display: block;
      padding: 12px 22px;
      border-bottom: 1px solid #D8D8D8;
      font-size: 17px;
      font-weight: 500;
      &:hover .menu__nav-link-label {
        transform: translate3d(7px, 0, 0);
      }
    }
  }
}

