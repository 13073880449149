.advantages {
  &__main {

  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

@include respond-up('large') {
  .advantages {
    &__items {
      margin: 0 -45px;
    }

    &__item {
      margin-bottom: 100px;
      padding: 0 45px;
      flex: 0 0 33.33333%;
    }
  }
}

@include respond-up('medium') {
  .advantages {
    &__arrows {
      display: none;
    }
  }
}

@include respond('medium') {
  .advantages {
    &__items {
      margin: 0 -24px;
    }

    &__item {
      margin-bottom: 60px;
      padding: 0 24px;
      flex: 0 0 50%;
    }
  }
}


@include respond-down('small') {
  .advantages {
    &__main {
      margin: 0 -22px;
    }

    &__arrows {
      padding-top: 26px;
    }
  }
}