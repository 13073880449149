.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;


  &__content {
    overflow: hidden;
  }
}