$modal-bg: rgba(0, 0, 0, 0.76) !default;

$modal-content-radius: 0 !default;
$modal-content-shadow: 3px 4px 35px rgba(92, 99, 100, 0.27) !default;
$modal-content-padding: 60px 66px 90px !default;
$modal-content-background: #fff !default;

$modal-closer-color: $modal-bg !default;
$modal-closer-size: 30px !default;

$modal-preloader-size: 30px;

.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  background: $modal-bg;
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &.opened {
    display: block;
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  padding: 80px 0;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &.opened {
    display: flex;
  }
}

.full {
  min-height: 100vh;
}

@include respond-up('medium') {
  .modal__layout {
    .modal__closer {
      display: none;
    }
  }
}

@include respond-down('small') {
  .modal__layout {
    padding: 54px 0 0;
  }
}

.modal__container {
  padding: $modal-content-padding;
  border-radius: $modal-content-radius;
  background: $modal-content-background;
  box-shadow: $modal-content-shadow;
  position: relative;
  max-width: 924px;
}

@include respond-up('medium') {
  .modal__container {
    .modal__closer {
      display: block;
    }
  }
}

@include respond('medium') {
  .modal__container {
    margin: 0 55px;
    padding: 50px 40px 60px;
  }
}

@include respond-down('medium') {
  .modal__container {
    max-width: 600px;
  }
  .full {
    .modal__container {
      width: 600px;
    }
  }
}

@include respond-down('small') {
  .modal__container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 54px);
    margin: 0 22px;
    padding: 26px 22px 36px;

    .modal__closer {
      display: none;
    }
  }
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  position: absolute;
  top: -16px;
  right: -55px;
  height: 100%;

  font-size: $modal-closer-size;
  color: $modal-closer-color;

  text-decoration: none;

  &::before {
    content: '';
    display: block;
    position: sticky;
    top: 0;
    height: 55px;
    width: 55px;
    background: url('../images/base/closer.svg') no-repeat center;
    cursor: pointer;
    opacity: .7;
    transition: opacity $default-transition;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

@include respond-down('small') {
  .modal__closer {
    height: calc(100% - 3px);
    top: 3px;
    right: 6px;
  }
}

.modal__loader {
  display: block;
  position: fixed;
  z-index: 3000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: $modal-bg;
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

body.modal-loading {
  overflow: hidden;
  $preloaderSize: 100px;

  .modal__loader {
    opacity: 1;
    visibility: visible;

    &:before, &:after {
      content: '';
      position: fixed;
      width: $preloaderSize;
      height: $preloaderSize;
      left: 50%;
      top: 50%;
      margin-left: -$preloaderSize/2;
      margin-top: -$preloaderSize/2;
      background-color: #000;
      border-radius: $preloaderSize/2;
      transform: scale(0);
    }

    &:before {
      background-color: lighten($primary-color, 15%);
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
      animation-delay: 0.4s;
    }

    &:after {
      background-color: $primary-color;
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}