.kit-info {
  margin-top: 120px;
  &__title {
    font-size: 29px;
    font-weight: 800;
    margin-bottom: 37px;
  }
}

@include respond-up('x-large') {
  .kit-info {
    margin-top: 120px;
  }
}

@include respond('large') {
  .kit-info {
    margin-top: 97px;
  }
}

@include respond('medium') {
  .kit-info {
    margin-top: 78px;
  }
}

@include respond-down('small') {
  .kit-info {
    margin-top: 51px;
    margin-bottom: 0;
  }
}