.pellet-form {
  &__title {
    text-align: center;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__products {
    flex: 0 0 53%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__product-container {
    flex: 1 1 100%;
  }

  &__product {
    background: $magnolia-color;
    display: flex;
    height: 100%;
  }

  &__product-image {
    background: {
      size: contain;
      repeat: no-repeat;
      position: 50% 50%;
    };
  }

  &__product-info-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__product-info {
    font-weight: 700;
    color: $black-color;
    letter-spacing: 0;
  }

  &__product-info-numbers {
    font-weight: 800;
    color: $primary-color;
    letter-spacing: 0;
    line-height: 1.6;
  }

  &__form-container {
    flex: 0 0 44%;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__form-title {
    font-weight: 800;
    color: $black-color;
    letter-spacing: 0;
    text-align: center;
  }

  &__form-phone {
    position: relative;

    input[type="text"] {
      padding-left: 47px;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      height: 22px;
      width: 19px;
      top: 16px;
      left: 17px;
      background: {
        image: url("../svg/phone-form.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      };
    }
  }

  &__form-unity {
    display: flex;
  }

  &__form-product-select {
    flex: 1 1 57%;
    position: relative;

    select {
      background-color: $magnolia-color;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      height: 22px;
      width: 19px;
      top: 12px;
      left: 17px;
      background: {
        image: url("../svg/case.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      };
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 12px;
      width: 17px;
      top: 22px;
      right: 13px;
      background: {
        image: url("../svg/arrow-bottom.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      };
    }
  }

  &__form-product-count {
    flex: 1 1 43%;
  }

  &__form-button {
    white-space: nowrap;
  }

  &__policy {
    text-align: center;
  }

  &__link {

  }
}

@include respond-up('x-large') {
  .pellet-form {
    &__title {
      margin-bottom: 87px;
    }

    &__container {

    }

    &__products {
      margin-bottom: -28px;
      padding-right: 34px;
    }

    &__product-container {
      padding-bottom: 28px;
    }

    &__product {
      padding: 46px 212px 54px 34px;
    }

    &__product-image {
      height: 135px;
      width: 249px;
      min-width: 249px;
    }

    &__product-info-container {
      margin-left: 36px;
    }

    &__product-info {
      font-size: 23px;
    }

    &__product-info-numbers {
      font-size: 50px;
    }

    &__form-container {
      border: 25px solid $magnolia-color;
      padding: 78px 137px;
    }

    &__form {
      input[type="text"] {
        height: 53px;
        background-color: $magnolia-color;
        border: 2px solid #ECECE3;
      }

      .label {
        display: none;
      }
    }

    &__form-title {
      font-size: 35px;
      margin-bottom: 26px;
    }

    &__form-phone {
      input[type="text"] {

      }
    }

    &__form-phone {
      margin-bottom: 12px;
    }

    &__form-unity {
      margin-bottom: 12px;
    }

    &__form-product-select {

      select {
        height: 100%;
        border-radius: 0;
        border: 2px solid #ECECE3;
        outline-offset: -1px;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-left: 49px;
      }
    }

    &__form-product-count {

    }

    &__form-button {
      margin-bottom: 17px;
    }

    &__policy {

    }

    &__link {

    }

    &__success {
      padding: 0 137px;
    }
  }
}

@include respond('large') {
  .pellet-form {
    &__title {
      margin-bottom: 74px;
    }

    &__container {

    }

    &__products {
      margin-bottom: -28px;
      padding-right: 34px;
    }

    &__product-container {
      padding-bottom: 28px;
    }

    &__product {
      padding: 46px 35px 32px 34px;
    }

    &__product-image {
      height: 135px;
      width: 249px;
      min-width: 249px;
    }

    &__product-info-container {
      margin-left: 36px;
    }

    &__product-info {
      font-size: 23px;
    }

    &__product-info-numbers {
      font-size: 50px;
    }

    &__form-container {
      border: 25px solid $magnolia-color;
      padding: 78px 69px;
    }

    &__form {
      input[type="text"] {
        height: 53px;
        background-color: $magnolia-color;
        border: 2px solid #ECECE3;
      }

      .label {
        display: none;
      }
    }

    &__form-title {
      font-size: 35px;
      margin-bottom: 26px;
    }

    &__form-phone {
      margin-bottom: 12px;
    }

    &__form-unity {
      margin-bottom: 12px;
    }

    &__form-product-select {

      select {
        height: 100%;
        border-radius: 0;
        border: 2px solid #ECECE3;
        outline-offset: -1px;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-left: 49px;
      }
    }

    &__form-product-count {

    }

    &__form-button {
      margin-bottom: 17px;
    }

    &__policy {

    }

    &__link {

    }

    &__success {
      padding: 0 69px;
    }
  }
}

@include respond('medium') {
  .pellet-form {
    &__title {
      margin-bottom: 74px;
    }

    &__container {
      flex-direction: column;
    }

    &__products {
      flex: 1 1 100%;
      margin-bottom: -18px;
      padding-bottom: 29px;
    }

    &__product-container {
      padding-bottom: 18px;
    }

    &__product {
      padding: 46px 130px 32px 34px;
    }

    &__product-image {
      height: 135px;
      width: 249px;
      min-width: 249px;
    }

    &__product-info-container {
      margin-left: 36px;
    }

    &__product-info {
      font-size: 23px;
    }

    &__product-info-numbers {
      font-size: 50px;
    }

    &__form-container {
      border: 20px solid $magnolia-color;
      padding: 66px 132px;
      flex: 1 1 100%;
    }

    &__form {
      input[type="text"] {
        height: 53px;
        background-color: $magnolia-color;
        border: 2px solid #ECECE3;
      }

      .label {
        display: none;
      }
    }

    &__form-title {
      font-size: 35px;
      margin-bottom: 26px;
    }

    &__form-phone {
      margin-bottom: 12px;
    }

    &__form-unity {
      margin-bottom: 12px;
    }

    &__form-product-select {

      select {
        height: 100%;
        border-radius: 0;
        border: 2px solid #ECECE3;
        outline-offset: -1px;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-left: 49px;
      }
    }

    &__form-product-count {

    }

    &__form-button {
      margin-bottom: 17px;
    }

    &__policy {

    }

    &__link {

    }

    &__success {
      padding: 0 132px;
    }
  }
}

@include respond-down('small') {
  .pellet-form {
    &__title {
      margin-bottom: 74px;
    }

    &__container {
      flex-direction: column;
    }

    &__products {
      flex: 1 1 100%;
      margin-bottom: -14px;
      padding-bottom: 36px;
    }

    &__product-container {
      padding-bottom: 14px;
    }

    &__product {
      padding: 28px 27px 24px 23px;
      flex-direction: column;
    }

    &__product-image {
      align-self: center;
      height: 123px;
      width: 229px;
      min-width: 229px;
    }

    &__product-info-container {
      margin-top: 27px;
      align-items: flex-start;
    }

    &__product-info {
      font-size: 20px;
      margin-bottom: 13px;
    }

    &__product-info-numbers {
      font-size: 32px;
    }

    &__form-container {
      margin: 0 -22px;
      border-top: 14px solid $magnolia-color;
      border-bottom: 14px solid $magnolia-color;
      padding: 39px 27px;
      flex: 1 1 100%;
    }

    &__form {
      input[type="text"] {
        height: 46px;
        background-color: $magnolia-color;
        border: 2px solid #ECECE3;
      }

      .label {
        display: none;
      }
    }

    &__form-title {
      font-size: 35px;
      margin-bottom: 26px;
    }

    &__form-phone {
      margin-bottom: 12px;

      &::before {
        top: 12px;
      }
    }

    &__form-unity {
      margin-bottom: 12px;
    }

    &__form-product-select {

      select {
        height: 100%;
        border-radius: 0;
        border: 2px solid #ECECE3;
        outline-offset: -1px;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-left: 49px;
      }

      &::after {
        top: 18px;
      }
    }

    &__form-product-count {

    }

    &__form-button {
      margin-bottom: 17px;
      display: flex;
      justify-content: center;
    }

    &__policy {

    }

    &__link {

    }

    &__success {
      padding: 0 27px;
    }
  }
}