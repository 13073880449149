body {
  font-family: $font;
  font-size: $body-font-size;
  line-height: 1.375;
  color: $black-color;

  &.is-animating {
    pointer-events: none;
  }
  &.menu-active {
    overflow: hidden;
  }
  &.is-loading {
    overflow: hidden;
  }
}