.menu-category {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s;

  &__list {

  }

  &__item {

  }

  &__link {

  }

  &__link-label {

  }

  &__link-icon {

  }

  &__image-list {

  }

  &__image-item {
    transition: opacity $default-transition;
    opacity: 0;

    &:first-child {
      opacity: 1;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

@include respond-up('large') {
  .menu-category {
    display: block;
    left: 50%;
    width: calc(100% - 24px);
    transform: translateX(-50%);
    top: 39px;
    background-color: #FFFFFF;
    box-shadow: 0 30px 40px 0 rgba(68,71,65,0.11);
    padding-bottom: 40px;



    &__list {
      padding-left: 51px;
      padding-top: 19px;
      width: 300px;
      margin-bottom: 53px;
    }

    &__item:not(:nth-last-child(1)) {
      border-bottom: 2px solid #F6F6F1;
    }

    &__item-link {
      display: block;
      padding: 15px 0;
      font-size: 15px;
      font-weight: 500;
      transition: color $default-transition;

      &:hover {
        color: $green-color
      }
    }

    &__link {
      width: 150px;
      margin-left: 51px;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 800;
      letter-spacing: 0.15em;
      color: $primary-color;
      margin-bottom: 40px;
      padding: 5px 5px 5px 0;
      display: flex;
      align-items: center;
    }

    &__link-label {
      margin-right: 8px;
    }

    &__link-icon {
      position: relative;
      top: -2px;
      display: block;
      transition: transform .5s ease-in-out;
    }

    &__link {
      &:hover {
        .menu-category__link-icon {
          transform: translate3d(9px, 0, 0);
        }
      }
    }

    &__image-list {
      position: absolute;
      top: 40px;
      right: 0;
      width: 300px;
      height: 230px;
    }

    &__image-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: {
        position: right;
        size: cover;
      };
    }
  }
}

@include respond-down('medium') {
  .menu-category {
    display: none;
  }
}