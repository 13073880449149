.recall-modal {
  max-width: 333px;
  &__form {
    .default-form {
      &__form {
        .form-field {
          input[type="text"] {
            position: relative;
            height: 53px;
            border: 2px solid #ECECE3;
            background: {
              color: #F6F6F1;
            };
          }
        }

        .form-field.name {
          margin-bottom: 12px;

          input {
            background: #F6F6F1 url('../svg/contacts-form.svg') no-repeat 16px center;
          }
        }
      }

      &__fields {
        margin-bottom: 12px;
      }

      &__wrap {
        display: flex;
        flex-direction: column;
      }

      &__fields {
        display: flex;
        flex-direction: column;
      }

      &__button {
        width: 100%;
      }

      &__success {
        display: flex;
        flex-direction: column;
      }

      &__success-image {
        margin-bottom: 33px;
        margin-right: 0;
      }

      &__success-title {
        text-align: center;
        font-size: 35px;
      }

      &__success-description {
        text-align: center;
      }
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__success-image {
    margin-bottom: 33px;
    margin-right: 0;
    height: 99px;
    width: 99px;
    background: {
      position: 50% 50%;
      size: contain;
      repeat: no-repeat;
    };
  }

  &__success-title {
    text-align: center;
    font-size: 35px;
    font-weight: 800;
    color: #333531;
    letter-spacing: 0;
    line-height: 1;
    max-width: 489px;
    margin-bottom: 35px;
  }

  &__success-description {
    text-align: center;
  }
}

@include respond-down('small') {
  .recall-modal {
    max-width: 280px;
    &__form {
      .default-form {
        &__form {
          .form-field {
            input[type="text"] {
              position: relative;
              height: 46px;
              border: 2px solid #ECECE3;
              background: {
                color: #F6F6F1;
              };
            }
          }

          .form-field.name {
            margin-bottom: 12px;

            input {
              background: #F6F6F1 url('../svg/contacts-form.svg') no-repeat 16px center;
            }
          }
        }

        &__fields {
          margin-bottom: 12px;
        }

        &__wrap {
          display: flex;
          flex-direction: column;
        }

        &__fields {
          display: flex;
          flex-direction: column;
        }

        &__button {
          width: 100%;
        }

        &__success {
          display: flex;
          flex-direction: column;
        }

        &__success-image {
          margin-bottom: 20px;
          margin-right: 0;
        }

        &__success-title {
          text-align: center;
          font-size: 25px;
        }

        &__success-description {
          text-align: center;
        }
      }
    }

    &__success {
      display: flex;
      flex-direction: column;
    }

    &__success-image {
      margin-bottom: 22px;
    }

    &__success-title {
      font-size: 30px;
      margin-bottom: 10px;
    }

    &__success-description {
      font-size: 15px;
    }
  }
}
