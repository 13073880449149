.header {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__nav {
    display: flex;
    position: relative;
  }

  &__contacts-phone {
    display: block;
    font-weight: 700;
  }

  &__contacts-label {
    color: $green-color;
    text-decoration: none;
  }
}

@include respond-up('large') {
  .header {
    position: relative;
    padding: 40px 70px 0 70px;
    z-index: 2000;
    //transition: all $default-transition;
    background-color: #FFFFFF;
    border-bottom: 1px solid transparent;

    &.sticky {
      position: fixed;
      margin: 0;
      padding: 20px 70px;
      width: 100%;
      background-color: #FFFFFF;
      border-bottom: 1px solid #F6F6F1;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //opacity: 0;
      //transform: translate3d(0, -27px, 0);
    }

    &__logo {
      width: 197px;
      height: 46px;
    }

    &__nav-link {
      position: relative;
      top: 4px;
      padding: 5px 12px 12px 12px;
      text-decoration: none;
      color: inherit;
      font-size: 16px;
      transition: color .3s ease-in-out;
      font-weight: 500;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 12px;
        width: calc(100% - 24px);
        height: 100%;
        border-bottom: 4px solid $primary-color;
        transform-origin: right top;
        transition: transform .4s ease-in-out;
        transform: scaleX(0);
      }
    }

    &__nav-item:not(:nth-child(-n+2)) &__nav-link {

      &:hover:before {
        transform-origin: left top;
        transform: scaleX(1);
      }

      &:hover {
        color: $primary-color;
      }
    }

    &__nav-item.active &__nav-link {
      color: $primary-color;
      &:before {
        transform-origin: left top;
        transform: scaleX(1);
      }
    }

    &__contacts-phone {
      font-size: 19px;
      font-weight: 700;
    }

    &__contacts-label {
      display: block;
      text-align: end;
    }

    &__menu-icon {
      display: none;
    }

    &__phone-icon {
      display: none;
    }
  }
  .header-index {
    transform: translate3d(0, -27px, 0);
    opacity: 0;
  }
}

@include respond-down('medium') {
  .header {
    height: 79px;
    position: sticky;
    top: 0;
    z-index: 2000;
    background-color: #FFFFFF;

    &__inner {
      padding: 15px 44px;
      border-bottom: 1px solid #D8D8D8;
    }

    &__logo {
      width: 197px;
      height: 46px;
    }

    &__nav {
      display: none;
    }

    &__right {
      position: relative;
      top: 4px;
      display: flex;
    }

    &__contacts-phone {
      display: block;
      margin-right: 40px;
    }

    &__contacts-label {
      display: none;
    }

    &__menu-icon {
      display: block;
      position: relative;
      width: 35px;
      height: 19px;
    }

    &__menu-icon-item {
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $primary-color;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 44%;
      }
      &:nth-child(3) {
        bottom: 0;
      }
    }

    &__phone-icon {
      display: none;
    }
  }
}

@include respond-down('small') {
  .header {
    height: 54px;
    &__inner {
      padding: 0 25px;
      height: 100%;
    }

    &__logo {
      height: 34px;
      width: 154px;
      margin-right: 59px;
    }

    &__right {
      position: relative;
      top: -1px;
      display: flex;
      align-items: center;
    }

    &__contacts-phone {
      display: none;
    }

    &__menu-icon {
      width: 24px;
      height: 16px;
    }

    &__menu-icon-item {
      height: 2px;
    }

    &__phone-icon {
      display: block;
      padding: 15px 25px;
      border-right: 2px solid $magnolia-color;
      border-left: 2px solid $magnolia-color;
      margin-right: 24px;
    }
  }
}