.production {
  &__container {

  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    flex: 0 0 50%;

    &_advantage {
      display: none;

      .tns-nav {
        margin: 20px -5px 0;
        display: flex;
        justify-content: center;
        button {
          width: 8px;
          height: 8px;
          background-color: $green-color;
          opacity: 0.27;
          border-radius: 100px;
          transition: $default-transition;
          margin: 0 5px;
        }

        .tns-nav-active {
          opacity: 1;
          transition: $default-transition;
        }
      }
    }
  }

  &__description-container {

  }

  &__description-title {
    font-weight: bold;
    color: #333531;
    letter-spacing: 0;
  }

  &__description {
    color: rgba(51,53,49,0.65);
    letter-spacing: 0;
    line-height: 1.7;
  }
}

@include respond-up('x-large') {
  .production {
    &__container {
      padding: 0 0 170px;
    }

    &__list {
      margin: 0 -22px -40px;
    }

    &__item {
      padding: 0 22px 40px;

      &_advantage {
        display: block;
        align-self: center;
        max-width: 50%;
      }
    }

    &__description-container {
      margin-top: 170px;
    }

    &__description-title {
      font-size: 45px;
      margin-bottom: 25px;
    }

    &__description {
      font-size: 17px;
    }
  }
}

@include respond('large') {
  .production {
    &__container {
      padding: 0 0 140px;
    }

    &__list {
      margin: 0 -15px -30px;
    }

    &__item {
      padding: 0 15px 30px;

      &_advantage {
        display: block;
        align-self: center;
        max-width: 50%;
      }
    }

    &__description-container {
      margin-top: 137px;
    }
  }
}

@include respond-up('large') {
  .production {
    &__description-title {
      font-size: 45px;
      margin-bottom: 25px;
    }

    &__description {
      font-size: 17px;
    }
  }
}

@include respond('medium') {
  .production {
    &__container {
      padding: 0 0 140px;
    }

    &__list {

    }

    &__item {
      padding-bottom: 20px;
      flex: 0 0 100%;
    }

    &__description-container {
      margin-top: 75px;
    }

    &__description-title {
      font-size: 38px;
      margin-bottom: 18px;
    }

    &__description {
      font-size: 17px;
    }
  }
}

@include respond-down('small') {
  .production {
    &__container {
      padding: 0 0 63px;
    }

    &__list {

    }

    &__item {
      padding-bottom: 16px;
      flex: 0 0 100%;
    }

    &__description-container {
      margin-top: 48px;
    }

    &__description-title {
      font-size: 25px;
      margin-bottom: 9px;
    }

    &__description {
      font-size: 16px;
    }
  }
}