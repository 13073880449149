$max-row-width: 1920;
$grid-columns: 12;

$small-breakpoint: 375;
$medium-breakpoint: 768;
$large-breakpoint: 1366;
$x-large-breakpoint: 1920;

$grid: (
  x-small: (
    from: 0,
    to: $small-breakpoint - 1,
    gutter: 10,
    spacer: 16
  ),
  small: (
    from: $small-breakpoint,
    to: $medium-breakpoint - 1,
    gutter: 16,
    spacer: 22
  ),
  medium: (
    from: $medium-breakpoint,
    to: $large-breakpoint - 1,
    gutter: 20,
    spacer: 44
  ),
  large: (
    from: $large-breakpoint,
    to: $x-large-breakpoint - 1,
    gutter: 30,
    spacer: 150
  ),
  x-large: (
    from: $x-large-breakpoint,
    gutter: 44,
    spacer: 220
  )
);

$body-font-size: 16px;
$font: "Geometria", sans-serif;
$h-font: "Geometria", sans-serif;

$t: transparent;

$primary-color: #CEAF72;
$green-color: #76B14D;
$black-color: #333531;
$gray-color: rgba(51,53,49,0.65);
$light-gray-color: #D8D8D8;
$alert-color: #b50000;
$magnolia-color: #f6f6f1;

$default-transition: .4s ease-in-out;