.action {
  &__title {
    display: none;
  }

  &__img {
    display: block;
  }

  &__text {
    color: $gray-color;
  }
}

@include respond-up('x-large') {
  .action {
    &__main {
      padding-bottom: 170px;
    }
  }
}

@include respond-up('large') {
  .action {
    &__main {
      display: flex;
      justify-content: space-between;
      padding-top: 82px;
    }

    &__description {
      flex: 0 0 65%;
    }

    &__description-name {
      font-size: 45px;
    }

    &__more {
      flex: 0 0 25%;
    }
  }
}

@include respond('large') {
  .action {
    &__main {
      padding-bottom: 150px;
    }
  }
}

@include respond-up('medium') {
  .action {
    &__img {
      &_mobile {
        display: none;
      }
    }

    &__description {
      font-size: 17px;
      line-height: 1.7;
    }

    &__description-name {
      font-weight: 800;
    }

    &__text {
      padding-top: 12px;
    }
  }
}

@include respond('medium') {
  .action {
    &__main {
      padding: 38px 0 72px;
    }

    &__description {
      padding-bottom: 72px;
    }

    &__description-name {
      font-size: 38px;
    }
  }
}

@include respond-down('small') {
  .action {
    &__img {
      &_desktop {
        display: none;
      }
    }

    &__main {
      padding: 30px 0 70px;
    }

    &__description {
      padding-bottom: 50px;
    }

    &__description-name {
      font-weight: 700;
      font-size: 25px;
    }

    &__text {
      padding-top: 10px;
      line-height: 1.375;
    }
  }
}