.about-works {
  &__head {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__title {
    font-weight: 800;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__items {
    display: flex;
    transform: translate3d(0,0,1);

    &::before {
      content: '';
    }
  }

  &__item {
    opacity: 0.5;
    transition: opacity $default-transition;

    &.tns-slide-active {
      opacity: 1;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
  }

  &__image {
    height: 15.5vw;
  }

  &__img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__name {
    font-weight: 500;
    letter-spacing: 0;
  }

  &__button {
    padding: 17px 10px;
    width: 224px;
  }

  &__arrows {
    transform: translateX(26px);
  }
}

@include respond-up('x-large') {
  .about-works {
    &__head {
      margin-top: 35px;
    }

    &__items {
      margin: 0 -22px;

      &::before {
        flex: 0 0 calc((100vw - 1480px) / 2);
      }
    }

    &__item {
      padding: 0 22px;
    }

    &__name {
      padding-top: 20px;
    }

    &__button {
      margin-top: 48px;
    }
  }
}

@include respond-up('large') {
  .about-works {
    &__title {
      font-size: 65px;
    }

    &__items {
      padding-top: 30px;
    }

    &__image {
      height: 15.5vw;
    }
  }
}

@include respond('large') {
  .about-works {
    &__head {
      margin-top: 40px;
    }

    &__items {
      margin: 0 -15px;

      &::before {
        flex: 0 0 150px;
      }
    }

    &__item {
      padding: 0 15px;
    }

    &__name {
      padding-top: 16px;
    }

    &__button {
      margin-top: 38px;
    }
  }
}

@include respond('medium') {
  .about-works {
    &__head {
      margin-top: 30px;
    }

    &__title {
      font-size: 48px;
    }

    &__items {
      padding-top: 22px;
      margin: 0 -10px;

      &::before {
        flex: 0 0 44px;
      }
    }

    &__item {
      padding: 0 10px;
    }

    &__image {
      height: 25vw;
    }

    &__name {
      padding-top: 10px;
      padding-right: 10px;
    }

    &__button {
      margin-top: 22px;
    }
  }
}

@include respond-down('small') {
  .about-works {
    &__head {
      margin-top: 60px;
    }

    &__title {
      font-size: 30px;
    }

    &__items {
      &::before {
        flex: 0 0 11px;
      }
    }

    &__item {
      padding: 0 11px;
    }

    &__arrows {
      display: none;
    }

    &__button {
      margin-top: 20px;
      width: 186px;
    }

    &__image {
      height: 51vw;
    }

    &__name {
      padding-top: 6px;
      padding-right: 10px;
      font-size: 15px;
    }
  }
}