.slider {
  position: relative;
  &__item {
    position: relative;
    left: 100%;
    z-index: 1;
    &.active {
      left: 0 !important;
    }
  }
}

.dots {
  margin-top: 30px;
  display: flex;
  &__item {
    width: 30px;
    height: 30px;
    border: 1px solid #000000;
    margin-right: 10px;
  }
}

.in {
  animation: in 1s ease-in-out;
}

.out {
  animation: out 1s ease-in-out;
}

.in-reverse {
  animation: in-reverse 1s ease-in-out;
}

.out-reverse {
  animation: out-reverse 1s ease-in-out;
}

@keyframes in {
  0% {
    z-index: 1;
    transform: translate3d(0, 0, 0);
  }
  99% {
    z-index: 1;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    z-index: 1;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes out {
  0% {
    z-index: 0;
    transform: translate3d(0, 0, 0);
  }
  99% {
    z-index: 0;
    transform: translate3d(-40%, 0, 0);
  }
  100% {
    z-index: 0;
    transform: translate3d(-40%, 0, 0);
  }
}

@keyframes in-reverse {
  0% {
    z-index: 1;
    transform: translate3d(-200%, 0, 0);
  }

  99% {
    z-index: 1;
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    z-index: 1;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes out-reverse {
  0% {
    z-index: 0;
    transform: translate3d(0%, 0, 0);
  }

  99% {
    z-index: 0;
    transform: translate3d(40%, 0, 0);
  }

  100% {
    z-index: 0;
    transform: translate3d(40%, 0, 0);
  }
}
