.section-tabs {
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__link {
    position: relative;
    font-weight: 800;
    line-height: 1;
    color: $black-color;
    white-space: nowrap;
    transition: color $default-transition;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:hover {
      color: $primary-color;

      &::before {
        transform-origin: left top;
        transform: scaleX(1);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-bottom: 4px solid $primary-color;
      transform-origin: right top;
      transition: transform .5s ease-in-out;
      transform: scaleX(0);
    }

    &_active {
      color: $primary-color;
      letter-spacing: 0;

      &::before {
        transform: scaleX(1);
      }
    }
  }
}

@include respond-up('large') {
  .section-tabs {
    &__list {
      padding-bottom: 74px;
    }

    &__item {
      padding: 0 22px;
    }

    &__link {
      padding: 10px 0;
      font-size: 25px;
    }
  }
}

@include respond('medium') {
  .section-tabs {
    &__list {
      padding-left: 180px;
      padding-bottom: 52px;
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 0 auto;

      &::before {
        content: '';
        height: 10px;
        flex: 0 0 44px;
      }

      &::after {
        content: '';
        height: 10px;
        flex: 0 0 44px;
      }
    }

    &__item {
      padding: 0 20px;
    }

    &__link {
      padding: 8px 0;
      font-size: 23px;
    }
  }
}

@include respond('small') {
  .section-tabs {
    &__item {
      padding: 0 12px;
    }
  }
}

@include respond-down('small') {
  .section-tabs {
    display: flex;
    justify-content: center;

    &__list {
      justify-content: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 0 10px 32px 10px;

      &::after {
        content: '';
        height: 10px;
        flex: 0 0 10px;
      }
    }

    &__link {
      padding: 6px 0;
      font-size: 19px;

      &::before {
        border-bottom: 3px solid $primary-color;
      }
    }
  }
}

@include respond-down('x-small') {
  .section-tabs {
    &__item {
      padding: 0 10px;
    }
  }
}