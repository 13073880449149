.pellet-table {
  width: 100%;

  &_large {

  }

  &_medium-down {
    display: none;
  }

  &__title-container {

  }

  &__title {

  }

  &__line {

  }

  &__item {

  }

  &__hidden {
    display: none;
  }

  &__item-image {

  }

  &__item-text {

  }
}

@include respond-up('large') {
  .pellet-table {
    width: 100%;
    &__title-container {

    }

    &__title {
      text-align: left;
      padding: 27px 0;
      font-size: 17px;
      font-weight: 700;
      color: $black-color;
      letter-spacing: 0;
      &:nth-child(1) {
        padding-left: 20px;
      }
    }

    &__line {
      &:nth-child(2n) {
        background-color: $magnolia-color;
      }
    }

    &__item {
      font-weight: 500;
      font-size: 15px;
      color: $black-color;
      letter-spacing: 0;
      vertical-align: middle;

      &:nth-child(1) {
        padding: 14px 0 14px 20px;
        display: flex;
        align-items: center;
        max-width: 260px;
      }
    }

    &__item-image {
      height: 56px;
      width: 56px;
      margin-right: 19px;
    }

    &__item-text {

    }
  }
}

@include respond('medium') {
  .pellet-table {

    &_large {
      display: none;
    }

    &_medium-down {
      display: block;
    }

    &__wrapper {
      background-color: $magnolia-color;
      padding: 28px 40px;
      margin-bottom: 18px;
    }

    &__wrapper-title-container {
      display: flex;
      align-items: center;
      margin-bottom: 29px;
    }

    &__wrapper-image {
      height: 56px;
      width: 56px;
      margin-right: 16px;
    }

    &__wrapper-title {
      font-weight: 700;
      font-size: 20px;
      color: $black-color;
      letter-spacing: 0;
    }

    &__wrapper-items {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -20px;
    }

    &__wrapper-item {
      flex: 0 0 25%;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }

    &__wrapper-item-title {
      font-size: 16px;
      color: $gray-color;
      letter-spacing: 0;
      margin-bottom: 4px;
    }

    &__wrapper-item-text {
      font-weight: 500;
      font-size: 16px;
      color: $black-color;
      letter-spacing: 0;
    }
  }
}

@include respond-down('small') {
  .pellet-table {
    &_large {
      display: none;
    }

    &_medium-down {
      display: block;
    }

    &__wrapper {
      background-color: $magnolia-color;
      padding: 18px 21px;
      margin-bottom: 18px;
    }

    &__wrapper-title-container {
      display: flex;
      align-items: center;
      margin-bottom: 29px;
    }

    &__wrapper-image {
      height: 56px;
      width: 56px;
      margin-right: 16px;
    }

    &__wrapper-title {
      font-weight: 700;
      font-size: 17px;
      color: $black-color;
      letter-spacing: 0;
    }

    &__wrapper-items {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -10px;
    }

    &__wrapper-item {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    &__wrapper-item-title {
      font-size: 16px;
      color: $gray-color;
      letter-spacing: 0;
      margin-bottom: 4px;
    }

    &__wrapper-item-text {
      font-weight: 500;
      font-size: 16px;
      color: $black-color;
      letter-spacing: 0;
    }
  }
}