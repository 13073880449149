


.list-enter-active {
    transition: all .8s;
}

.list-leave-active {
    position: absolute;
}


.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */
{
    opacity: 0;
    transform: translateY(30px);
}
