.material {
  margin-top: 57px;

  &__list {
    display: flex;
    flex-wrap: wrap;

  }
  &__title {
    font-size: 17px;
    color: rgba(51, 53, 49, 0.5);
    margin-bottom: 22px;
  }
}

@include respond-up('x-large') {
  .material {
    &__list {
      margin-left: -10px;
    }
  }
}

@include respond('large') {
  .material {
    margin-bottom: 57px;
    &__list {
      margin-left: -8px;
    }
  }
}

@include respond('medium') {
  .material {
    margin-bottom: 57px;
    &__list {
      margin-left: -8px;
    }
  }
}

@include respond-down('small') {
  .material {
    margin-bottom: 40px;
    &__list {

    }
  }
}
