.menu-list {
  display: flex;
  margin-bottom: 33px;
  justify-content: space-between;

}

@include respond-down('small') {
  .menu-list {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}