.pellet {
  &__container {

  }

  &__offer {

  }

  &__table {

  }
}

@include respond-up('x-large') {
  .pellet {
    &__container {

    }

    &__offer {

    }

    &__description {
      margin-bottom: 160px;
    }

    &__advantages {
      margin-bottom: 187px;
    }

    &__quality {
      margin-bottom: 210px;
    }

    &__form {
      margin-bottom: 230px;
    }

    &__about {
      margin-bottom: 180px;
    }

    &__also {
      margin-bottom: 164px;
    }
  }
}

@include respond('large') {
  .pellet {
    &__container {

    }

    &__offer {

    }

    &__table {
      margin: 50px 0 123px;
    }

    &__description {
      margin-bottom: 124px;
    }

    &__advantages {
      margin-bottom: 100px;
    }

    &__quality {
      margin-bottom: 161px;
    }

    &__form {
      margin-bottom: 163px;
    }

    &__about {
      margin-bottom: 170px;
    }

    &__also {
      margin-bottom: 164px;
    }
  }
}

@include respond('medium') {
  .pellet {
    &__container {

    }

    &__offer {
      margin-bottom: 120px;
    }

    &__table {
      margin: 0 0 86px;
    }

    &__description {
      margin-bottom: 139px;
    }

    &__quality {
      margin-bottom: 190px;
    }

    &__form {
      margin-bottom: 119px;
    }

    &__about {
      margin-bottom: 140px;
    }

    &__also {
      margin-bottom: 137px;
    }
  }
}

@include respond-down('small') {
  .pellet {
    &__container {

    }

    &__offer {
      margin-bottom: 10px;
    }

    &__table {
      margin: 0 0 33px;
    }

    &__description {
      margin-bottom: 70px;
    }

    &__quality {
      margin-bottom: 100px;
    }

    &__advantages {
      margin-bottom: 57px;
    }

    &__form {
      margin-bottom: 62px;
    }

    &__about {
      margin-bottom: 70px;
    }

    &__also {
      margin-bottom: 63px;
    }
  }
}